import React, {useState} from "react";
import {API} from "aws-amplify";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBInput, MDBRow} from "mdb-react-ui-kit";
import {AccountSettings} from "@aws-amplify/ui-react";

export interface AccountSettingsTabParams {
    reload: () => void
    splashText: string
    slug: string
}

export const AccountsSettingsTab = ({splashText, reload, slug}: AccountSettingsTabParams) => {
    const [input, setInput] = useState(splashText);

    const handleInputChange = (e: any) => {
        const newValue = e.target.value;
        setInput(newValue);
    };
    const doSubmit = async (e: any) => {
        e.preventDefault();
        const request = {
            type: "EditSplash",
            splash: input,
        }
        const amplifyInit = {
            body: request
        }
        await API.post("BackendAuth", "write_creator", amplifyInit);
        reload();
        alert("Zaktualizowane.")
    };


    return (<MDBRow className='row-cols-1 row-cols-lg-2 g-4'>
            <MDBCol>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>Edytuj tekst na Twoim Landing Page'u</MDBCardTitle>
                        <MDBCardText>
                            <a target={"_blank"} href={"https://subdog.xyz/" + slug}><MDBBtn color={"info"} block className={"mb-3"}>
                                Otwórz swój Landing Page
                            </MDBBtn></a>
                            <form onSubmit={doSubmit}>
                                <MDBInput
                                    className='flex-grow-1 mb-0'
                                    type='text'
                                    id={`splash_text`}
                                    required
                                    value={input}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <MDBBtn color={"light"} type='submit' block className={"mt-3"}>
                                    Edytuj
                                </MDBBtn>
                            </form>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol>
                <MDBCard className={"mt-1"}>
                    <MDBCardBody>
                        <MDBCardText className={"bg-white p-2"}>
                            <AccountSettings.ChangePassword/>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}