import {useState} from "react";
import {Row, Col, Drawer} from "antd";
import {useTranslation, withTranslation} from "react-i18next";
import Container from "../../common/Container";
import {SvgIcon} from "../../common/SvgIcon";
import {Button} from "../../common/Button";
import {
    HeaderSection,
    LogoContainer,
    Burger,
    NotHidden,
    Menu,
    CustomNavLinkSmall,
    Label,
    Outline,
    Span,
} from "./styles";
import {Link} from "react-router-dom";

const Header = () => {
    let {t} = useTranslation();
    const [visible, setVisibility] = useState(false);

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => scrollTo("questions")}>
                    <Span>{t("frequently-asked-questions-top")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
                    <Span>{t("why-am-i-doing-this-top")}</Span>
                </CustomNavLinkSmall>
                <Link to={"/account"}>
                    <CustomNavLinkSmall
                        style={{width: "180px"}}
                    >
                        <Span>
                            <Button>{t("your-account-button-top")}</Button>
                        </Span>
                    </CustomNavLinkSmall>
                </Link>
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify="space-between">
                    <Menu>subdog</Menu>
                    <LogoContainer to="/" aria-label="homepage">
                        {/*<p>subdog c5n</p>*/}
                        {/*<SvgIcon src="logo.svg" width="101px" height="64px" />*/}
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem/>
                    </NotHidden>
                    <Burger onClick={toggleButton}>
                        <Outline/>
                    </Burger>
                </Row>
                <Drawer closable={false} open={visible} onClose={toggleButton}>
                    <Col style={{marginBottom: "2.5rem"}}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline/>
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem/>
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
