import React from "react";
import {MenuItemType} from "antd/lib/menu/hooks/useItems";
import {LineChartOutlined} from "@ant-design/icons";
import {SimpleCardWrapper} from "../../commons/SimpleCardWrapper";
import {Button, Card} from "antd";
import {useTranslation} from "react-i18next";

export const AccountStatsKey = 'account-stats'

export function AccountStatsMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: AccountStatsKey,
        icon: React.createElement(LineChartOutlined),
        label: t("admin-panel-account-statistics"),
    }
}

export interface AccountStatsContentNewProps {
    stripeLink: string
}

export const AccountStatsNewContent = ({stripeLink}: AccountStatsContentNewProps) => {
    const {t} = useTranslation()
    return (
        <SimpleCardWrapper>
            <Card
                hoverable
                title={t("c5n-statistics-card-header")}
                actions={[
                    <a href={stripeLink} target={"_blank"} rel="noreferrer">
                        <Button>{t("c5n-statistics-card-stripe-link")}</Button>
                    </a>
                ]}
            >
                {t("c5n-statistics-card-explanation")}
            </Card>
        </SimpleCardWrapper>
    )
}