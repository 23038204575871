import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {useTranslation} from "react-i18next";
import {FeedbackButton} from "../../feedback/FeedbackButton";

export const C5NPostStripeRegister = () => {
    const {t} = useTranslation()
    return (
        <>
            <FeedbackButton showText={true}/>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <h1>{t("c5n-post-register")}</h1>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}