import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTypography
} from "mdb-react-ui-kit";
import React from "react";
import {BalanceResponse, SubscriptionDatum} from "../App";
import {Step, StepLabel, Stepper} from "@mui/material";

export interface FinancialStatsParams {
    balance_response: BalanceResponse
    subscription_data: SubscriptionDatum[]
}

let steps = (balance_response: BalanceResponse) => {
    let available_balances = balance_response.available.map(bi => {
        return (bi.amount / 100).toString() + ' ' + bi.currency
    }).join(", ").toUpperCase()
    let pending_balances = balance_response.pending.map(bi => {
        return (bi.amount / 100).toString() + ' ' + bi.currency
    }).join(", ").toUpperCase()
    let current_date = new Date();
    let current_month = current_date.getMonth() + 1;
    let current_day = current_date.getDate();
    let anchor_as_number = balance_response.anchor as unknown as number;
    if (current_day > anchor_as_number) {
        current_month += 1
    }
    if (current_month > 12) {
        current_month = 1;
    }
    let month_text = "";
    if (current_month === 1) {
        month_text = "Stycznia"
    } else if (current_month === 2) {
        month_text = "Lutego"
    } else if (current_month === 3) {
        month_text = "Marca"
    } else if (current_month === 4) {
        month_text = "Kwietnia"
    } else if (current_month === 5) {
        month_text = "Maja"
    } else if (current_month === 6) {
        month_text = "Czerwca"
    } else if (current_month === 7) {
        month_text = "Lipca"
    } else if (current_month === 8) {
        month_text = "Sierpnia"
    } else if (current_month === 9) {
        month_text = "Września"
    } else if (current_month === 10) {
        month_text = "Października"
    } else if (current_month === 11) {
        month_text = "Listopada"
    } else if (current_month === 12) {
        month_text = "Grudnia"
    }
    return [
        {
            key: 1,
            label: 'W trakcie weryfikacji ',
            special_value: pending_balances,
            description: `Stripe zatrzymuje środki przez 7 dni celem weryfikacji. Po weryfikacji znajdą się w kroku poniżej.`,
        },
        {
            key: 2,
            label: 'Środki na Twoim koncie ',
            special_value: available_balances,
            description:
                'Zweryfikowane i gotowe do wypłaty. Wypłata następuje w kroku poniżej.',
        },
        {
            key: 3,
            label: 'Na Twoim koncie będą ',
            special_value: anchor_as_number + ' ' + month_text,
            description: `Tego dnia środki z kroku powyżej powinny być  dostępne na Twoim koncie.`,
        },
    ];
}


function getStepIcon(step_key: number) {
    if (step_key === 1) {
        return MagnifyingGlassDollarIcon
    } else if (step_key === 2) {
        return PiggyBankIcon
    } else {
        return BuildingColumnsIcon
    }
}

let PiggyBankIcon = () => {
    return <MDBIcon icon={"piggy-bank"} size={"2x"} color={"success"}/>
}

let MagnifyingGlassDollarIcon = () => {
    return <MDBIcon icon={"magnifying-glass-dollar"} size={"2x"} color={"warning"}/>
}

let BuildingColumnsIcon = () => {
    return <MDBIcon icon={"building-columns"} size={"2x"} color={"info"}/>
}

function getPillStyle(step_key: number) {
    if (step_key === 1) {
        return "warning"
    } else if (step_key === 2) {
        return "success"
    } else {
        return "info"
    }
}

function payoutTranslation(payout_status: string): string {
    if (payout_status === "paid") {
        return "Wypłacono"
    } else if (payout_status === "pending") {
        return "Oczekuje"
    } else if (payout_status === "in_transit") {
        return "W drodze"
    } else if (payout_status === "failed") {
        return "Nieudana"
    } else if (payout_status === "canceled") {
        return "Anulowana"
    } else {
        return payout_status
    }
}

export let FinancialStats = ({balance_response, subscription_data}: FinancialStatsParams) => {
    let calculated_steps = steps(balance_response);
    let active_subscription_count = subscription_data.filter(sd => sd.status === "active").length;
    let currency_to_monthly_amount = aggregateSubscriptionAmounts(subscription_data);
    let currency_to_monthly_string: string[] = []
    currency_to_monthly_amount.forEach((value, key) => {
        currency_to_monthly_string.push(value.toFixed(2) + ' ' + key.toUpperCase());
    })
    if (currency_to_monthly_string.length === 0) {
        currency_to_monthly_string.push("0")
    }
    return (
        <>
            <MDBRow className='row-cols-1 row-cols-lg-2 g-4'>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle className={"text-center"}>Informacje o subskrypcjach</MDBCardTitle>
                            <MDBCardText>
                                <p className={"text-center"}><b>Szacowany miesięczny przelew na konto:</b> <br/>
                                    <MDBBadge pill
                                              style={{fontSize: "1.25rem"}}
                                              color={"success"}>{currency_to_monthly_string.join(", ")}</MDBBadge></p>
                                <p className={"text-center"}><b>Liczba aktywnych subskrybentów:</b> <br/><MDBBadge pill
                                                                                                            style={{fontSize: "1.25rem"}}
                                                                                                            color={"info"}>{active_subscription_count}</MDBBadge></p>
                                <MDBTypography note noteColor='warning'>
                                    <strong>Uwaga:</strong> Powyższa kwota to tylko szacunek, nie jest to kwota gwarantowana. Zależy to od tego ilu
                                    subskrybentów zdecyduje się na opłacenie subskrypcji w danym miesiącu. Moment w którym dany subskrybent się zarejestrował
                                    ma również wpływ czy przelew pojawi się w danym miesiącu czy w kolejnym.
                                </MDBTypography>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle className={"text-center"}>Płatności</MDBCardTitle>
                            <MDBCardText>
                                <div>
                                    <Stepper orientation="vertical">
                                        {calculated_steps.map((step) => (
                                            <Step key={step.label}>
                                                <StepLabel
                                                    StepIconComponent={getStepIcon(step.key)}
                                                    optional={<span>{step.description}</span>}>
                                                    <div className={"h6 text-center"}>
                                                        {step.label}
                                                        <MDBBadge pill
                                                                  style={{fontSize: "1rem"}}
                                                                  color={getPillStyle(step.key)}>{step.special_value}</MDBBadge>
                                                    </div>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>

                                </div>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle className={"text-center"}>Ostatnie 5 wypłat</MDBCardTitle>
                            <MDBCardText>
                                <MDBTable responsive className={"text-center"}>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Data wysłania</th>
                                            <th>Kwota</th>
                                            <th>Data dotarcia na konto</th>
                                            <th>Status</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {balance_response.last_payouts.slice(0, 5).map((payout, index) => {
                                            let payout_status = payoutTranslation(payout.status);
                                            let payout_amount = (payout.amount / 100).toFixed(2) + ' ' + payout.currency.toUpperCase();
                                            return <tr>
                                                <td>{payout.created}</td>
                                                <td>{payout_amount}</td>
                                                <td>{payout.arrival_date}</td>
                                                <td>{payout_status}</td>
                                            </tr>
                                        })}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                {/*<ProductSellingSection response={response}/>*/}
            </MDBRow>
        </>
    )
}

function aggregateSubscriptionAmounts(subscription_data: SubscriptionDatum[]): Map<string, number> {
    const currencyMap = new Map<string, number>();

    subscription_data
        .forEach(subscription => {
            const user_fee_ratio = 1 - (subscription.application_fee_percent / 100);
            subscription.subscription_item.forEach(item => {
                if (item.plan.active && item.plan.interval === 'month') {
                    const adjustedAmount = (item.plan.amount / 100) * user_fee_ratio;
                    const currentAmount = currencyMap.get(item.plan.currency) || 0;
                    currencyMap.set(item.plan.currency, currentAmount + adjustedAmount);
                }
            });
        });
    return currencyMap;
}