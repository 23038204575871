import {MenuItemType} from "antd/lib/menu/hooks/useItems";
import React from "react";
import {UserOutlined} from "@ant-design/icons";
import {Button, Card, Form, Input, message} from "antd";
import {API} from "aws-amplify";
import TextArea from "antd/lib/input/TextArea";
import {ExperienceType} from "../../App";
import {SimpleCardWrapper} from "../../commons/SimpleCardWrapper";
import {useTranslation} from "react-i18next";

export const AccountManagementKey = 'account-management'

export function AccountManagementMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: AccountManagementKey,
        icon: React.createElement(UserOutlined),
        label: t("admin-panel-account-management"),
    }
}


export interface AccountManagementContentProps {
    reload: () => void
    currentSplash?: string
    experienceType: ExperienceType
    stripeLink: string
}

export const AccountManagementContent = ({
                                             currentSplash,
                                             reload,
                                             experienceType,
                                             stripeLink
                                         }: AccountManagementContentProps) => {
    const {t} = useTranslation()
    return (<>
            <SimpleCardWrapper>
                <Card
                    hoverable
                    actions={[
                        <a href={stripeLink} target={"_blank"} rel="noreferrer"><Button type="primary">
                            {t("account-management-strip-login-card-button-text")}
                        </Button></a>
                    ]}
                >
                    <Card.Meta title={t("account-management-strip-login-card-title")}
                               description={t("account-management-strip-login-card-description")}/>

                </Card>
            </SimpleCardWrapper>
            {experienceType === "OldFullExperience" && (
                <SimpleCardWrapper>
                    <Card
                        hoverable
                    >
                        <Card.Meta title={"Edytuj teskt na Twoim Landing Page'u"}
                                   description={
                                       <Form
                                           name={"edit-splash-page"}
                                           initialValues={
                                               {
                                                   "new-splash": currentSplash
                                               }
                                           }
                                           size={"large"}
                                           onFinish={
                                               async (e) => {
                                                   const request = {
                                                       type: "EditSplash",
                                                       splash: e['new-splash'],
                                                   }
                                                   const amplifyInit = {
                                                       body: request
                                                   }
                                                   await API.post("BackendAuth", "write_creator", amplifyInit);
                                                   reload();
                                                   message.success("Zaktualizowane.", 5)
                                               }
                                           }
                                       >
                                           <Form.Item>
                                               <TextArea
                                                   name={"new-splash"}
                                                   required={true}
                                                   autoSize={{minRows: 3, maxRows: 5}}
                                                   defaultValue={currentSplash}
                                               >
                                                   <Input/>
                                               </TextArea>
                                           </Form.Item>
                                           <Form.Item>
                                               <Button type="primary" htmlType="submit" block>
                                                   Edytuj
                                               </Button>
                                           </Form.Item>
                                       </Form>
                                   }/>

                    </Card>
                </SimpleCardWrapper>
            )}</>
    )
}