import {
    MDBBtn,
    MDBCardBody,
    MDBCardText,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane
} from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import {MessageInfo, MessageProductsData} from "../App";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {API} from "aws-amplify";


interface CardBodyParams {
    items: MessageProductsData,
    reloadItems: () => void
}

const futureDate = (date: string, compareDate: string) => {
    return date === undefined || date === null || new Date(date) > new Date(compareDate);
}

export let ListMessagesCardBody = ({items, reloadItems}: CardBodyParams) => {
    const currentDate = new Date().toLocaleDateString('en-CA');

    const [showModal, setShowModal] = React.useState(false);
    const [editedItem, setEditedItem] = React.useState<MessageInfo | null>(null);
    const [isEditingOrder, setIsEditingOrder] = React.useState(false);
    const [reorderedItems, setReorderedItems] = useState<MessageInfo[]>([]);
    const [beforeReorderingItems, setBeforeReorderingItems] = useState<MessageInfo[]>([]);

    useEffect(() => {
        let sortedItems = items.future_messages.sort((a, b) => a.message_no.value - b.message_no.value);
        let editableStartIndex = sortedItems.findIndex((msd) => futureDate(msd.latest_sent_date, currentDate));
        let modifiableItems = sortedItems.slice(editableStartIndex);

        setReorderedItems([...modifiableItems]);
        setBeforeReorderingItems([...modifiableItems]);
    }, [currentDate, items]);
    const toggleEditingOrder = () => {
        setIsEditingOrder(!isEditingOrder);
    };

    const discardChanges = () => {
        setIsEditingOrder(false);
        setReorderedItems([...beforeReorderingItems]);
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setReorderedItems([...reorderedItems]);
    };

    const handleOpenModal = (item: MessageInfo) => {
        setEditedItem(item);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const saveOrderChanges = async () => {
        if (JSON.stringify(reorderedItems) !== JSON.stringify(beforeReorderingItems)) {
            let messageNo: number = Math.min(...reorderedItems.map(item => item.message_no.value));
            let changedMessages = []
            for (let idx = 0; idx < reorderedItems.length; idx++) {
                let current_msg = reorderedItems[idx];
                changedMessages.push(
                    {
                        message_no: messageNo,
                        content: current_msg.message_content
                    }
                )
                messageNo++;
            }
            let editedContentBody = {
                type: "UpdateMessage",
                messages: changedMessages,
            }
            const amplifyInit = {
                body: editedContentBody
            }
            await API.post("BackendAuth", "write_creator", amplifyInit);
            setBeforeReorderingItems([...reorderedItems])
            reloadItems();
        }

        setIsEditingOrder(false);

    };

    const submitChangeContent = async (e: any) => {
        e.preventDefault();
        if (editedItem !== null) {
            let editedContentBody = {
                type: "UpdateMessage",
                messages: [{
                    message_no: editedItem.message_no.value,
                    content: editedItem.message_content
                }],
            }
            const amplifyInit = {
                body: editedContentBody
            }
            await API.post("BackendAuth", "write_creator", amplifyInit).then(() => {
                alert("Wiadomość zaktualizowana");
            });

        }
        reloadItems();
        setShowModal(false);
    };

    const [justifyActive, setJustifyActive] = useState('tab1');

    const handleJustifyClick = (value: string) => {
        if (value === justifyActive) {
            return;
        }
        setJustifyActive(value);
    };


    return (
        <>
            <MDBCardBody>
                <MDBCardText>
                    <MDBTabs justify className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                Przyszłe wiadomości
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                Wysłane wiadomości
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => {
                                setEditedItem(items.welcome_message_info);
                                handleJustifyClick('tab3')
                            }} active={justifyActive === 'tab3'}>
                                Edytuj wiadomość powitalną
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                    <MDBTabsContent>
                        <MDBTabsPane show={justifyActive === 'tab1'}>
                            <div className={"mb-2"}>
                                {isEditingOrder ? (
                                    <div>
                                        <MDBBtn color={"primary"} className={"w-50"} onClick={saveOrderChanges}>Zapisz
                                            zmiany</MDBBtn>
                                        <MDBBtn color={"danger"} className={"w-50"} onClick={discardChanges}>Porzuć
                                            zmiany</MDBBtn>
                                    </div>
                                ) : (
                                    <MDBBtn color={"primary"} onClick={toggleEditingOrder} block>Zmień
                                        Kolejność</MDBBtn>
                                )}
                            </div>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div ref={provided.innerRef}>
                                            <MDBTable responsive>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th scope='col'>Treść</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody {...provided.droppableProps}>
                                                    <>
                                                        {reorderedItems.map((item, index) => {
                                                            return (
                                                                <Draggable
                                                                    key={item.message_no.value}
                                                                    draggableId={item.message_no.value.toString()}
                                                                    index={index}
                                                                    isDragDisabled={!isEditingOrder}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <>
                                                                            <tr
                                                                                key={item.message_no.toString()}
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <td>
                                                                                    {isEditingOrder ? (
                                                                                        <MDBIcon fas icon='grip-lines'
                                                                                                 className='ms-2 fa-pull-right'/>
                                                                                    ) : <>
                                                                                        <MDBIcon
                                                                                            fas
                                                                                            icon='pencil-alt'
                                                                                            className='ms-2 fa-pull-right'
                                                                                            onClick={() => handleOpenModal(item)}
                                                                                        />
                                                                                    </>}
                                                                                    {item.message_content}
                                                                                </td>
                                                                            </tr>
                                                                        </>)
                                                                    }
                                                                </Draggable>);
                                                        })}
                                                        {provided.placeholder}
                                                    </>
                                                </MDBTableBody> </MDBTable></div>)}
                                </Droppable>
                            </DragDropContext>

                        </MDBTabsPane>

                        <MDBTabsPane show={justifyActive === 'tab2'}>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th scope='col'>Wysłano ostatnio</th>
                                        <th scope='col'>Treść</th>
                                        <th scope='col'>Ile wysłano?</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {items.sent_messages.map((item) => {
                                        return (<tr
                                            key={item.message_no.value.toString()}
                                            className={
                                                currentDate === item.latest_sent_date ? "table-info" : ""
                                            }>
                                            <td>
                                                <div>
                                                    {item.message_no.value === 0 ? "Wiadomość powitalna" : item.latest_sent_date}
                                                </div>
                                            </td>
                                            <td>
                                                {item.message_content}
                                                {item.message_no.value === 0 && (
                                                    <MDBIcon
                                                        fas
                                                        icon='pencil-alt'
                                                        className='ms-2 fa-pull-right'
                                                        onClick={() => handleOpenModal(item)}
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                {item.sent_to_count}
                                            </td>
                                        </tr>);
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBTabsPane>
                        <MDBTabsPane show={justifyActive === 'tab3'}>
                            <form onSubmit={submitChangeContent}>
                                <MDBModalHeader>Zmień treść</MDBModalHeader>
                                <MDBModalBody>
                                    <MDBInput
                                        value={editedItem?.message_content || ""}
                                        className='flex-grow-1 mb-0'
                                        type='text'
                                        label='Treść wiadomości'
                                        required
                                        minLength={10}
                                        maxLength={140}
                                        onChange={e => setEditedItem({...editedItem!, message_content: e.target.value})}
                                    />
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn color={"primary"} type='submit'>Zapisz</MDBBtn>
                                    <MDBBtn color={"secondary"} type='button'
                                            onClick={handleCloseModal}>Zamknij</MDBBtn>
                                </MDBModalFooter>
                            </form>
                        </MDBTabsPane>
                    </MDBTabsContent>
                </MDBCardText>
            </MDBCardBody>

            <MDBModal show={showModal} setShow={setShowModal}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <form onSubmit={submitChangeContent}>
                            <MDBModalHeader>Zmień treść</MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput
                                    value={editedItem?.message_content || ""}
                                    className='flex-grow-1 mb-0'
                                    type='text'
                                    label='Treść wiadomości'
                                    required
                                    minLength={10}
                                    maxLength={140}
                                    onChange={e => setEditedItem({...editedItem!, message_content: e.target.value})}
                                />
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color={"primary"} type='submit'>Zapisz</MDBBtn>
                                <MDBBtn color={"secondary"} type='button' onClick={handleCloseModal}>Zamknij</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}