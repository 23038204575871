import React, {ChangeEvent, useRef, useState} from "react";
import {API} from "aws-amplify";
import {MDBBtn, MDBCardBody, MDBCardText, MDBCardTitle, MDBInput, MDBTypography} from "mdb-react-ui-kit";

export interface AddMessagesCardBodyParams {
    reloadItems: () => void
}

export let AddMessagesCardBody = ({reloadItems}: AddMessagesCardBodyParams) => {
    const [inputs, setInputs] = useState([{id: 1, value: ''}]);

    const handleInputChange = (e: any, id: number) => {
        const newValue = e.target.value;
        setInputs(inputs.map(input => (input.id === id ? {...input, value: newValue} : input)));
    };

    const addInput = () => {
        setInputs([...inputs, {id: Date.now(), value: ''}]);
    };

    const removeInput = (id: number) => {
        if (inputs.length > 1) {
            setInputs(inputs.filter(input => input.id !== id));
        }
    };

    const doSubmit = async (e: any) => {
        e.preventDefault();
        const contents = inputs.map(input => input.value)
        const request = {
            type: "AddMessages",
            contents: contents,
        }
        const amplifyInit = {
            body: request,
            response: true,
        }
        let result = await API.post("BackendAuth", "write_creator", amplifyInit);
        setInputs([{id: 1, value: ''}]);

        if (result.status === 200) {
            await reloadItems();
            alert("Dodano wiadomości");
        } else {
            alert("Coś poszło nie tak :(")
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.size <= 1048576) { // 1MB limit
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const lines = (e.target?.result as string).split('\n')
                    .map(line => line.trim())
                    .filter(line =>
                        line.length >= 10 && line.length <= 140
                    );
                setInputs(lines.map((line, index) => ({id: index + 1, value: line})));

            };
            reader.readAsText(file);
        } else {
            console.log('File is too large or not selected');
        }
    };

    return (
        <MDBCardBody>
            <MDBCardTitle>Dodaj wiadomości</MDBCardTitle>
            <MDBCardText>
                <form onSubmit={doSubmit}>
                    {inputs.map(({id}) => (
                        <div key={id} className="d-flex align-items-center mb-3">
                            <MDBInput
                                className='flex-grow-1 mb-0'
                                type='text'
                                id={`form-${id}`}
                                label='Treść wiadomości'
                                required
                                minLength={10}
                                maxLength={140}
                                value={inputs.filter(v => v.id === id)[0].value}
                                onChange={(e) => handleInputChange(e, id)}
                            />
                            {id > 1 && (
                                <MDBBtn color={"danger"} type='button' className='ml-2 mb-0 p-2 ms-1'
                                        onClick={() => removeInput(id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </MDBBtn>
                            )}
                        </div>
                    ))}
                    <MDBBtn color={"light"} type='button' className={"mb-2"} onClick={addInput} block>
                        Dodaj kolejną wiadomość
                    </MDBBtn>
                    <div className={"mb-2"}>
                        <MDBBtn color={"light"} type='button' block onClick={handleFileButtonClick} className={"mb-1"}>
                            Dodaj wiele wiadomości z pliku
                        </MDBBtn>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".txt"
                            style={{display: 'none'}} // Hidden input
                        />
                        <MDBTypography note noteColor='warning'>
                            <strong>Uwaga:</strong> Plik musi mieć rozszerzenie .txt. Każda linia pliku będzie
                            traktowana jako osobna wiadomość. Wiadomości
                            muszą być dłuższe niż 10 znaków i krótsze niż 140 znaków. Każda wiadomość musi być w osobnej
                            linii. Wiadomości automatycznie wypełnią pola powyżej, tak by zweryfikować efekt.
                        </MDBTypography>
                    </div>
                    <MDBBtn type='submit' block>
                        Dodaj wiadomości
                    </MDBBtn>
                </form>
            </MDBCardText>
        </MDBCardBody>
    );
};
