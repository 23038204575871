import {MenuItemType} from "antd/lib/menu/hooks/useItems";
import React from "react";
import {FileTextOutlined} from "@ant-design/icons";
import {SimpleCardWrapper} from "../../commons/SimpleCardWrapper";
import {Card, Skeleton} from "antd";
import {CreatorDataResponse} from "../../App";
import {API} from "aws-amplify";

export const InvoicesKey = 'invoices-key'

export const InvoicesMenuItem: MenuItemType = {
    key: InvoicesKey,
    icon: React.createElement(FileTextOutlined),
    label: 'Faktury',
}

interface InvoicesResponse {
    has_more: boolean
    iterator_key: string | undefined
    invoices: InvoiceData[]
}

interface InvoiceData {
    date: number
    amount: number
    currency: string
    invoice_pdf: string
}

export const InvoicesContent = () => {

    let [latestResponse, setLatestResponse] = React.useState(undefined as InvoicesResponse | undefined)

    React.useEffect(() => {
        const initialRequest = {
            iterator_key: undefined
        }
        const amplifyInit = {
            body: initialRequest
        }
        let futureInvoices: Promise<InvoicesResponse> = API.post("BackendAuth", "get_invoices", amplifyInit)
        futureInvoices.then(response => {
            console.log(response)
            setLatestResponse(response)
        })
    }, [])


    if (latestResponse === undefined) {
        return (
            <SimpleCardWrapper>
                <Card>
                    <Skeleton active/>
                </Card>
            </SimpleCardWrapper>
        )
    } else {
        return (
            <>HELLO BITCH</>
        )
    }

}
