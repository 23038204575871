export class AwsAuthenticatorConfig {
    region: string
    userPoolId: string
    userPoolWebClientId: string

    constructor(region: string, userPoolId: string, userPoolWebClientId: string) {
        this.region = region
        this.userPoolId = userPoolId
        this.userPoolWebClientId = userPoolWebClientId
    }

    static fromEnvironment(): AwsAuthenticatorConfig {
        return new AwsAuthenticatorConfig(
            process.env.REACT_APP_REGION as string,
            process.env.REACT_APP_USER_POOL_ID as string,
            process.env.REACT_APP_WEB_CLIENT_ID as string
        )
    }

}