import React from "react";
import Home from "../landy-react-template-master/src/pages/Home";
import {FeedbackButton} from "./feedback/FeedbackButton";

export const LandingPage = () => {
    return (
        <>
            <FeedbackButton showText={true}/>
            <Home/>
        </>
    )
}