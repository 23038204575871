import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTabs,
    MDBTabsContent,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsPane
} from "mdb-react-ui-kit";
import Markdown from "react-markdown";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const platformName = "subdog.xyz c5n"
const email = "piotr.w.lewandowski+c5n@gmail.com"

const polishTerms = `## Warunki Usługi dla Twórców na ${platformName}

**Witamy na ${platformName}!**

Niniejsze Warunki Usługi („Warunki”) regulują korzystanie z platformy ${platformName} jako Twórcy. Tworząc konto i przesyłając treści, oświadczasz, że przeczytałeś, zrozumiałeś i zgodziłeś się być związanym niniejszymi Warunkami. Jeśli nie zgadzasz się z którąkolwiek częścią niniejszych Warunków, nie możesz korzystać z platformy.

**1. Definicje**

* **Twórca:** Ty, osoba fizyczna lub prawna sprzedająca wirtualne produkty na platformie.
* **Treść:** Wirtualne produkty oferowane na sprzedaż na platformie, w tym między innymi oprogramowanie, szablony, e-booki, projekty itp.
* **Nabywca:** Użytkownik platformy, który kupuje wirtualne produkty od Twórców.
* **${platformName}:** Platforma umożliwiająca sprzedaż wirtualnych produktów od Twórców do Nabywców.

**2. Twoje Obowiązki**

* Jesteś wyłącznie odpowiedzialny za treści, które przesyłasz na platformę. Gwarantujesz, że posiadasz wszelkie niezbędne prawa, licencje i pozwolenia na sprzedaż treści.
* Zgadzasz się, aby Twoje treści:
    * Zgodne ze wszystkimi obowiązującymi przepisami i regulacjami, w tym z prawami własności intelektualnej.
    * Nie są nielegalne, szkodliwe, obraźliwe, nienawistne, dyskryminujące ani groźne.
    * Nie naruszają praw do prywatności innych osób.
    * Nie zawierają pornografii ani materiału seksualnie jawnego.
* Udzielasz ${platformName} niewyłącznej, ogólnoświatowej, bezpłatnej licencji na używanie Twojego imienia, podobizny i wszelkich znaków towarowych związanych z Twoimi treściami do celów marketingowych i promocyjnych.
* Zgadzasz się odpowiadać na pytania i opinie Nabywców.

**3. Sprzedaż Treści, Opłaty i Zwroty**

* Ustalasz cenę i opis swojej treści.
* ${platformName} obsługuje całą realizację płatności za pośrednictwem Stripe, bezpiecznego procesora płatności zewnętrznego.
* Masz prawo do ustalenia własnej polityki zwrotów. Jednak ${platformName} zastrzega sobie prawo do dokonywania zwrotów Nabywcom według własnego uznania, nawet jeśli Twoja polityka tego nie przewiduje.
* Rozumiesz, że wszystkie transakcje są ostateczne, a ${platformName} nie ponosi odpowiedzialności za żadne spory między Tobą a Nabywcami.
* **Dla Twórców z siedzibą w Polsce:** Pobierzemy opłatę za platformę w wysokości 5,55 PLN lub 5,55% ceny sprzedaży (w zależności od tego, która kwota jest wyższa) przed przekazaniem Ci zarobków.

**4. Dane i Prywatność**

* Przyjmujesz do wiadomości, że ${platformName} gromadzi pewne informacje o Tobie, takie jak Twoje imię, nazwisko, adres e-mail i dane płatnicze. Informacje te są wykorzystywane do przetwarzania Twoich płatności, dostarczania Ci usług platformy i komunikowania się z Tobą w sprawie Twojego konta.
* Aby uzyskać więcej informacji o naszych praktykach dotyczących danych, zapoznaj się z naszą Polityką Prywatności.

**5. Twoje Konto i Usuwanie Treści**

* Jesteś odpowiedzialny za utrzymanie bezpieczeństwa swojego konta i hasła.
* ${platformName} zastrzega sobie prawo do dezaktywacji Twojego konta lub usunięcia Twoich treści w dowolnym momencie, bez powiadomienia, z dowolnego powodu, w tym:
    * Naruszenie niniejszych Warunków
    * Przesyłanie nielegalnych lub szkodliwych treści
    * Niepłacenie opłat
    * Bezczynność

**6. Komunikacja i Aktualizacje**

* Zgadzasz się otrzymywać komunikaty od ${platformName} dotyczące Twojego konta, aktualizacji platformy i ważnych ogłoszeń.
* Możesz zrezygnować z tych komunikatów w dowolnym momencie.

## Warunki Usługi dla Twórców na ${platformName} (kontynuacja)

**7. Ogólne Warunki Prawne**

* Niniejsze Warunki podlegają i są interpretowane zgodnie z prawem polskim i prawem Unii Europejskiej.
* Zgadzasz się rozstrzygać wszelkie spory wynikające z niniejszych Warunków poprzez wiążący arbitraż w Polsce.
* Renuncjujesz z prawa do procesu z udziałem ławy przysięgłych lub udziału w zbiorowym powództwie.
* Niniejsze Warunki stanowią całość umowy między Tobą a ${platformName} dotyczącą korzystania z platformy.

**8. Kontakt**

Jeśli masz jakiekolwiek pytania dotyczące niniejszych Warunków, prosimy o kontakt pod adresem ${email}.`

const englishTerms = `## Updated Terms of Service for Creators on ${platformName}

**Welcome to ${platformName}!**

These Terms of Service ("Terms") govern your use of ${platformName} as a Creator. By creating an account and uploading content, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use the platform.

**1. Definitions**

* **Creator:** You, the individual or entity selling virtual products on the platform.
* **Content:** The virtual products you offer for sale on the platform, including but not limited to software, templates, ebooks, designs, etc.
* **Buyer:** A user of the platform who purchases virtual products from Creators.
* **${platformName}:** The platform facilitating the sale of virtual products from Creators to Buyers.

**2. Your Responsibilities**

* You are solely responsible for the content you upload to the platform. You warrant that you have all necessary rights, licenses, and permissions to sell the content.
* You agree to ensure that your content:
    * Complies with all applicable laws and regulations, including intellectual property laws.
    * Is not illegal, harmful, offensive, hateful, discriminatory, or threatening.
    * Does not violate the privacy rights of others.
    * Does not include pornography or sexually explicit material.
* You grant ${platformName} a non-exclusive, worldwide, royalty-free license to use your name, likeness, and any trademarks associated with your content for marketing and promotional purposes.
* You agree to be responsive to buyer inquiries and feedback.

**3. Content Sales, Fees, and Refunds**

* You set the price and description for your content.
* ${platformName} handles all payment processing through Stripe, a secure third-party payment processor.
* **For Polish-based Creators:** We will deduct a platform fee of either 5.55 PLN or 5.55% of the sale price (whichever is higher) before transferring your earnings to you.
* You have the right to set your own refund policy. However, ${platformName} reserves the right to issue refunds to Buyers at its own discretion, even if your policy does not provide for it.
* You understand that all sales are final and ${platformName} is not responsible for any disputes between you and Buyers.
* **Payments:** We aim to transfer your earnings to you by the 8th of each month, however, due to Stripe processing times, actual arrival may vary.

**Platform Fee Adjustments:**

* We reserve the right to **reduce the platform fee for Polish-based Creators at any time without prior notice**. Any such reductions will be clearly communicated and implemented immediately.
* However, any **increases in the platform fee for Polish-based Creators will require at least one month's written notice** from ${platformName}. We will communicate any proposed fee increases via email and on the platform dashboard.

**4. Data and Privacy**

* You acknowledge that ${platformName} collects certain information about you, such as your name, email address, and payment information. This information is used to process your payments, provide you with platform services, and communicate with you regarding your account.
* For more information about our data practices, please see our Privacy Policy.

**5. Your Account and Content Removal**

* You are responsible for maintaining the security of your account and password.
* ${platformName} reserves the right to deactivate your account or remove your content at any time, without notice, for any reason, including:
    * Violation of these Terms
    * Upload of illegal or harmful content
    * Non-payment of fees
    * Inactivity

**6. Communication and Updates**

* You agree to receive communications from ${platformName} regarding your account, platform updates, and important announcements.
* You can opt out of these communications at any time.

**7. General Legal Terms**

* These Terms are governed by and construed in accordance with the laws of Poland and the European Union.
* You agree to resolve any disputes arising out of these Terms through binding arbitration in Poland.
    * You waive any right to a jury trial or to participate in a class action lawsuit.
    * These Terms constitute the entire agreement between you and ${platformName} concerning your use of the platform.

**8. Contact**

If you have any questions about these Terms, please contact us at ${email}.`

export const C5NTermsOfServiceBuyer = () => {
    const {i18n} = useTranslation();
    const is_polish = i18n.language === 'pl';
    const [fillActive, setFillActive] = useState(is_polish ? 'tab1' : 'tab2');
    const handleFillClick = (value: string) => {
        if (value === fillActive) {
            return;
        }

        setFillActive(value);
    };

    return (
        <MDBContainer fluid>
            <MDBRow>
                <MDBCol>
                    <MDBTabs fill className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                                Polski
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                                English
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    <MDBTabsContent>
                        <MDBTabsPane show={fillActive === 'tab1'}><Markdown>{polishTerms}</Markdown></MDBTabsPane>
                        <MDBTabsPane show={fillActive === 'tab2'}><Markdown>{englishTerms}</Markdown></MDBTabsPane>
                    </MDBTabsContent>
                </MDBCol>
            </MDBRow>
        </MDBContainer>)
}