import React, {useState} from "react";
import {API} from "aws-amplify";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCheckbox,
    MDBInput,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {MessageInfo} from "../App";

interface SpecialMessageTabParams {
    sent_messages: MessageInfo[],
    reloadItems: () => void;
}

export let SpecialMessageTab = ({sent_messages, reloadItems}: SpecialMessageTabParams) => {
    let special_message_monthly_limit = 2;
    let message_left = special_message_monthly_limit - sent_messages.length;
    const [input, setInput] = useState("");

    const handleInputChange = (e: any) => {
        const newValue = e.target.value;
        setInput(newValue);
    };

    const doSendSpecialMessage = async (e: any) => {
        e.preventDefault();
        let current_date = new Date()
        let message_no = current_date.getFullYear() * 10000 + current_date.getMonth() * 100 + sent_messages.length;
        const request = {
            type: "SendSpecialMessage",
            message_no: message_no,
            content: input,
        }
        const amplifyInit = {
            body: request
        }
        await API.post("BackendAuth", "write_creator", amplifyInit);
        reloadItems();
        setInput("");
    };

    return (<>
        <MDBCard className={"m-2"}>
            <MDBCardBody>
                <MDBCardTitle>Wyślij wiadomość specjalną. W tym miesiącu pozostały <MDBBadge
                    className='ms-2'>{message_left}</MDBBadge>
                </MDBCardTitle>
                <MDBCardText>
                    <form onSubmit={doSendSpecialMessage}>
                        <MDBInput
                            className='flex-grow-1 mb-2'
                            type='text'
                            id={`form-id`}
                            label='Treść wiadomości'
                            required
                            minLength={10}
                            maxLength={140}
                            value={input}
                            onChange={(e) => handleInputChange(e)}
                        />
                        <MDBCheckbox
                            className={"mb-2"}
                            type={'checkbox'}
                            required={true}
                            label={"Rozumiem, że w każdym miesiącu mogę wysłać nie więcej niż " + special_message_monthly_limit + " wiadomości na żądanie."}
                        >
                        </MDBCheckbox>

                        <MDBBtn className={"mt-2"} disabled={message_left < 1} color={"light"} type='submit' block>
                            Wyślij wiadomość
                        </MDBBtn>
                    </form>
                </MDBCardText>
            </MDBCardBody>
        </MDBCard>
        <MDBCard>
            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Wysłana dnia</th>
                        <th scope='col'>Treść</th>
                        <th scope='col'>Ile wysłano?</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {sent_messages.map(msd => (<tr>
                        <td>{msd.latest_sent_date}</td>
                        <td>{msd.message_content}</td>
                        <td>{msd.sent_to_count}</td>
                    </tr>))}
                </MDBTableBody>
            </MDBTable>
        </MDBCard>
    </>)
}