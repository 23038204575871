import React, {useEffect, useState} from 'react';
import {TopNavigationUserData} from "./TopNavigation";
import {Authenticator, translations, useAuthenticator} from '@aws-amplify/ui-react';
import {AwsAuthenticatorConfig} from "./config/AwsAuthenticatorConfig";
import {Amplify, API, Auth, I18n} from "aws-amplify";
import {MDBCard, MDBCol, MDBContainer, MDBRow, MDBTabsContent, MDBTabsPane} from "mdb-react-ui-kit";
import {ListMessagesCardBody} from "./messages/ListMessagesCardBody";
import {AddMessagesCardBody} from "./messages/AddMessages";
import {CreatorNavbar} from "./navigation/CreatorNavbar";
import {WaitingScreen} from "./auth/WaitingScreen";
import {AuthScreen} from "./auth/AuthScreen";
import {SpecialMessageTab} from "./messages/SpecialMessageTab";
import {ProductStats} from "./product/ProductStats";
import {FinancialStats} from "./financial/FinancialStats";
import {AccountsSettingsTab} from "./user_conf/UserConfigTab";
import {C5NViewApp} from "./c5n/C5NViewApp";
import {ActiveCreatorTab} from "./data/ActiveCreatorTab";
import {C5NAddVirtualProduct} from "./c5n/tabs/C5NAddVirtualProduct";
import {C5NListVirtualProducts} from "./c5n/tabs/C5NListVirtualProducts";
import {Content} from "antd/lib/layout/layout";
import {FeedbackButton} from "./feedback/FeedbackButton";
import {Alert, Col, Flex} from "antd";
import {Link} from "react-router-dom";
import {AdminPanel} from "./adminpanel/AdminPanel";
import {useTranslation} from "react-i18next";

let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
let api_object: any[];
if (apiEndpoint !== undefined) {
    api_object = [
        {
            name: "BackendAuth",
            endpoint: apiEndpoint,
            custom_header: async () => {
                return {
                    Authorization:
                        `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                }
            },
        }
    ]
} else {
    api_object = [];
}

const config = AwsAuthenticatorConfig.fromEnvironment()
Amplify.configure({
    Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
        usernameAttributes: 'email',
    },
    API: {
        endpoints: api_object
    }
})
I18n.putVocabularies(translations);
export const useAwsAuthenticatorDataProvider: () => TopNavigationUserData | undefined = () => {
    const {user} = useAuthenticator()
    let userData: TopNavigationUserData | undefined
    if (user?.attributes?.email !== undefined) {
        userData = {displayName: user.attributes.email, logOutAction: () => Auth.signOut()}
    }
    return userData
}

function App() {
    return (
        <>
            <Authenticator.Provider>
                <AuthenticationContent/>
            </Authenticator.Provider>
        </>
    );
}

export type ExperienceType = "OldFullExperience" | "NewExperience";

export interface CreatorDataResponse {
    response_type: ExperienceType
    missing_fields: string[];
    stripe_login_link: string;
    c5n_products: C5NProduct[];
    is_polish: boolean

    splash_text: string;
    balance_response: BalanceResponse | undefined;
    items_selling_data: ItemsSellingData[];
    message_products_data: MessageProductsData | undefined;
    has_sms_product: boolean;
    subscription_data: SubscriptionDatum[]
    creator_slug: string,
}

export interface C5NProduct {
    product_name: string,
    product_description: string,
    price: number,
    currency: string,
    stripe_link_url: string,
    last_update_time_epoch_secs: number,
    author_name: string,
    image_url_link: string | null,
}

export interface ItemsSellingData {
    payment_link_id: string,
    payment_link_url: string,
    active: boolean,
    maybe_fee_percent: number | null,
    maybe_fee_amount: number | null,
    line_items: LineItem[],
}

export interface LineItem {
    product_id: string,
    description: string,
    amount_total: number,
    amount_tax: number,
    currency: string,
}

export interface BalanceResponse {
    available: BalanceItem[],
    connect_reserved: BalanceItem[],
    pending: BalanceItem[],
    interval: String,
    anchor: String,
    last_payouts: PayoutData[],
}

export interface BalanceItem {
    amount: number;
    currency: string;
}

export interface MessageProductsData {
    welcome_message_info: MessageInfo;
    sent_messages: MessageInfo[];
    future_messages: MessageInfo[];
    special_messages: MessageInfo[];
}

export interface MessageInfo {
    message_no: NumberValue;
    is_welcome: boolean;
    is_editable: boolean;
    latest_sent_date: string;
    sent_to_count: number;
    message_content: string;
}

export interface NumberValue {
    value: number;
}

export interface PayoutData {
    amount: number;
    arrival_date: string;
    created: string;
    currency: string;
    status: string;
}

export interface SubscriptionDatum {
    status: string,
    subscription_item: SubscriptionItem[],
    application_fee_percent: number

}

export interface SubscriptionItem {
    plan: SubscriptionPlan
}

export interface SubscriptionPlan {
    active: boolean,
    amount: number,
    currency: string,
    interval: string,
}

export const fetchCreatorData = async (setResponse: (value: (((prevState: (CreatorDataResponse | undefined)) => (CreatorDataResponse | undefined)) | CreatorDataResponse | undefined)) => void) => {
    let response: CreatorDataResponse = await API.get("BackendAuth", "read_creator", {});
    setResponse(response);
    return response;
};

interface UserLoggedInParams {
    userData: TopNavigationUserData
}

let AuthenticationContent = () => {
    let userData = useAwsAuthenticatorDataProvider();
    let result;
    const {t} = useTranslation()
    if (userData === undefined) {
        result = (
            <Content>
                <FeedbackButton showText={true}/>
                <Flex justify={"center"} align={"center"} gap={"middle"}>
                    <Col>
                        <Alert
                            message={t("auth-screen-how-to-set-up-account")}
                            description={<>{t("auth-screen-how-to-set-up-account-login-prefix")} <Link to={"/"}>{t("auth-screen-how-to-set-up-account-login-main-page")}</Link> {t("auth-screen-how-to-set-up-account-login-suffix")}</>}
                            type="info"
                            showIcon
                            closable
                            className={"mt-3"}
                        />
                        <AuthScreen/>
                    </Col>
                </Flex>
            </Content>
        )
    } else {
        result = (<AdminPanel userData={userData}/>)
    }
    return (
        <>{result}</>
    );
}


let UserLoggedIn = ({userData}: UserLoggedInParams) => {
    const [activeTab, setActiveTab] = useState('financial_stats' as ActiveCreatorTab);
    const [response, setResponse] = useState(undefined as CreatorDataResponse | undefined);
    const [reloadKey, setReloadKey] = useState(0);
    const forceReload = () => setReloadKey(prev => prev + 1);
    useEffect(() => {
        if (userData !== undefined && response === undefined) {
            fetchCreatorData(setResponse);
        }
    }, [reloadKey]);
    useEffect(() => {
        fetchCreatorData(setResponse);
    }, [reloadKey]);
    let result;
    if (response === undefined) {
        result = (
            <WaitingScreen/>
        )
    } else if (response.response_type === "NewExperience") {
        result = <C5NViewApp response={response} forceReload={forceReload}/>
    } else {
        result = <>
            <header>
                <CreatorNavbar tabActivator={(at: ActiveCreatorTab) => setActiveTab(at)}
                               hasSmsProducts={response.has_sms_product}/>
            </header>
            <MDBContainer>
                <div className={"mt-5 pt-3"}>
                    <MDBRow center>
                        <MDBCol>
                            <MDBTabsContent>

                                <MDBTabsPane show={activeTab === 'add_messages'}>
                                    <MDBCard className={"mb-1"}>
                                        <AddMessagesCardBody reloadItems={forceReload}/>
                                    </MDBCard>
                                </MDBTabsPane>
                                <MDBTabsPane
                                    show={activeTab === 'list_messages' && response.message_products_data !== undefined}>
                                    {response.message_products_data !== undefined && (<MDBCard>
                                        <ListMessagesCardBody
                                            items={response.message_products_data as MessageProductsData}
                                            reloadItems={forceReload}/>
                                    </MDBCard>)}
                                </MDBTabsPane>
                                <MDBTabsPane show={activeTab === 'send_on_demand_message'}>
                                    {response.message_products_data !== undefined && (
                                        <SpecialMessageTab
                                            sent_messages={response.message_products_data.special_messages}
                                            reloadItems={forceReload}/>)}

                                </MDBTabsPane>
                                <MDBTabsPane show={activeTab === 'product_stats'}>
                                    {response.message_products_data !== undefined && (
                                        <ProductStats sent_messages={response.message_products_data.sent_messages}/>)}
                                </MDBTabsPane>
                                <MDBTabsPane show={activeTab === 'financial_stats'}>
                                    {response.balance_response !== undefined && (
                                        <FinancialStats balance_response={response.balance_response}
                                                        subscription_data={response.subscription_data}/>)}

                                </MDBTabsPane>
                                <MDBTabsPane show={activeTab === 'account_settings'}>
                                    <AccountsSettingsTab splashText={response.splash_text} reload={forceReload}
                                                         slug={response.creator_slug}/>
                                </MDBTabsPane>
                                <C5NAddVirtualProduct fillActive={activeTab} setFillActive={setActiveTab}
                                                      force_reload={forceReload}/>
                                <C5NListVirtualProducts fillActive={activeTab} c5n_products={response.c5n_products}
                                                        forceReload={forceReload} slug={response.creator_slug}/>
                            </MDBTabsContent>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </>;
    }
    return result;
}

export interface CreatorStatsParams {
    response: CreatorDataResponse
}

export default App;
