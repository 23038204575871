import {Layout, Menu, Row} from "antd";
import {MenuDividerType, MenuItemType} from "antd/lib/menu/hooks/useItems";
import React, {useEffect, useState} from "react";
import {LogoutOutlined, FolderViewOutlined} from "@ant-design/icons";
import {Auth} from "aws-amplify";
import {AccountManagementContent, AccountManagementKey, AccountManagementMenuItem} from "./sections/AccountManagement";
import {
    AddVirtualProductContents,
    AddVirtualProductKey,
    VirtualMenuTopItem,
    YourVirtualProductsContents,
    YourVirtualProductsKey
} from "./sections/VirtualProducts";
import {C5NProduct, CreatorDataResponse, ExperienceType, fetchCreatorData} from "../App";
import {WaitingScreen} from "../auth/WaitingScreen";
import {AccountStatsKey, AccountStatsMenuItem, AccountStatsNewContent} from "./sections/AccountStats";
import {
    AddMessageContents,
    AddMessageKey,
    FinancialStatsContent,
    FinancialStatsKey,
    ListMessagesContents,
    ListMessagesKey,
    MessagesMenuItem,
    SendOnDemandMessageContents,
    SendOnDemandMessageKey,
    StatisticsAndDataMenuItem,
    YourProductStatsContent,
    YourProductStatsKey
} from "./sections/ClassicalSubDogThings";
import {InvoicesContent, InvoicesKey} from "./sections/Invoices";
import {FillInDetails} from "./sections/FillInDetails";
import {TopNavigationUserData} from "../TopNavigation";
import {useTranslation} from "react-i18next";

const {Content, Sider} = Layout;

export interface AdminPanelProps {
    userData: TopNavigationUserData
}

export const AdminPanel = ({userData}: AdminPanelProps) => {
    const [response, setResponse] = useState(undefined as CreatorDataResponse | undefined);
    useEffect(() => {
        fetchCreatorData(setResponse);
    }, []);
    if (response === undefined) {
        return (<WaitingScreen/>)
    }
    if (response.missing_fields.length > 0) {
        return (<FillInDetails response={response}/>)
    }
    return (
        <LoadedAdminPanel response={response} setResponse={setResponse} userData={userData}/>
    )
}

export interface LoadedAdminPanelProps {
    userData: TopNavigationUserData
    response: CreatorDataResponse
    setResponse: (response: CreatorDataResponse) => void
}

export const LoadedAdminPanel = ({userData, response, setResponse}: LoadedAdminPanelProps) => {
    let [content, setContent] = useState(<AddVirtualProductContents setResponse={setResponse}
                                                                    isPolish={response.is_polish}/>)
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width={8.33 * userData.displayName.length}
            >
                <div className="demo-logo-vertical"/>
                <UserMenu setContent={setContent} creatorProducts={response.c5n_products} setResponse={setResponse}
                          displayName={userData.displayName}
                          experience={response.response_type}
                          response={response}/>
            </Sider>
            <Layout>
                <Content style={{margin: '24px 16px 0'}}>
                    <Row gutter={[16, 16]}>
                        {content}
                    </Row>
                </Content>
            </Layout>
        </Layout>
    )
}

const menuItemClickHandler = (
    setContent: (content: any) => void,
    setResponse: (response: CreatorDataResponse) => void,
    creatorProducts: C5NProduct[],
    userData: CreatorDataResponse
) => (e: any) => {
    if (e.key === LogoutKey) {
        Auth.signOut().then(() => {
            window.location.reload();
        });
    } else if (e.key === AccountManagementKey) {
        setContent(<AccountManagementContent
            reload={() => window.location.reload()}
            currentSplash={userData.splash_text}
            experienceType={userData.response_type}
            stripeLink={userData.stripe_login_link}/>)
    } else if (e.key === AddVirtualProductKey) {
        setContent(<AddVirtualProductContents setResponse={setResponse} isPolish={userData.is_polish}/>)
    } else if (e.key === YourVirtualProductsKey) {
        setContent(<YourVirtualProductsContents products={creatorProducts} setResponse={setResponse}/>)
    } else if (e.key === AccountStatsKey) {
        setContent(<AccountStatsNewContent stripeLink={userData.stripe_login_link}/>)
    } else if (e.key === FinancialStatsKey && userData.balance_response) {
        setContent(<FinancialStatsContent balance_response={userData.balance_response}
                                          subscription_data={userData.subscription_data}/>)
    } else if (e.key === YourProductStatsKey && userData.message_products_data) {
        setContent(<YourProductStatsContent sent_messages={userData.message_products_data.sent_messages}/>)
    } else if (e.key === AddMessageKey) {
        setContent(<AddMessageContents/>)
    } else if (e.key === ListMessagesKey && userData.message_products_data) {
        setContent(<ListMessagesContents response={userData}/>)
    } else if (e.key === SendOnDemandMessageKey && userData.message_products_data) {
        setContent(<SendOnDemandMessageContents sent_messages={userData.message_products_data.special_messages}/>)
    } else if (e.key === InvoicesKey) {
        setContent(<InvoicesContent/>)
    } else if (e.key === "go-to-your-page") {
        window.open("https://subdog.xyz/" + userData.creator_slug, "_blank")
    }

}

interface UserMenuProps {
    setContent: (content: any) => void,
    displayName: string,
    setResponse: (response: CreatorDataResponse) => void,
    creatorProducts: C5NProduct[]
    experience: ExperienceType
    response: CreatorDataResponse
}

const UserMenu = ({setContent, setResponse, creatorProducts, experience, response, displayName}: UserMenuProps) => {
    const {t} = useTranslation();
    let items = [
        {
            key: 'user-id',
            label: displayName,
        } as MenuItemType,
        {
            key: 'go-to-your-page',
            label: t("admin-panel-open-sells-page"),
            icon: React.createElement(FolderViewOutlined),
        } as MenuItemType,
        {} as MenuDividerType,
        VirtualMenuTopItem(t),
        AccountStatsMenuItem(t),
        AccountManagementMenuItem(t),
        {} as MenuDividerType]

    if (experience === "OldFullExperience") {
        items.push(StatisticsAndDataMenuItem)
        items.push(MessagesMenuItem)
        items.push({} as MenuDividerType)
    }

    items.push(LogoutMenuItem(t))
    return (<Menu theme="dark" mode="inline" defaultSelectedKeys={['file-library']}
                  items={items}
                  onClick={menuItemClickHandler(setContent, setResponse, creatorProducts, response)}
    >
    </Menu>)
}

const LogoutKey = 'logout'

function LogoutMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: LogoutKey,
        danger: true,
        icon: React.createElement(LogoutOutlined),
        label: t("admin-panel-log-out"),
    }
}