import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle} from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import {CategoryScale, Chart, LinearScale, LineElement, PointElement, TimeScale} from "chart.js";
import {Line} from "react-chartjs-2";
import {MessageInfo} from "../App";

export interface ProductStatsParams {
    sent_messages: MessageInfo[]
}

export let ProductStats = ({sent_messages}: ProductStatsParams) => {
    return (
        <>
            <MDBCard className={"mb-2"}>
                <MDBCardBody>
                    <MDBCardTitle>Przybliżona ilość wysłanych wiadomości w ostatnich 30 dniach</MDBCardTitle>
                    <MDBCardText>
                        <MessageChart sent_messages={sent_messages}/>
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </>
    )
}
const processData = (data: MessageInfo[]) => {
    const last30Days: { [key: string]: number } = {};
    const today = new Date();
    for (let i = 0; i < 30; i++) {
        const date = new Date(today.getTime() - (i * 24 * 60 * 60 * 1000));
        const yyyy = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString().padStart(2, '0');
        const dd = date.getDate().toString().padStart(2, '0');
        last30Days[`${yyyy}-${mm}-${dd}`] = 0;
    }

    data.forEach((datum) => {
        if (last30Days[datum.latest_sent_date] !== undefined) {
            last30Days[datum.latest_sent_date] = datum.sent_to_count;
        }
    });

    return {
        labels: Object.keys(last30Days).reverse(),
        datasets: [{
            label: 'Message Count',
            data: Object.values(last30Days).reverse(),
            borderColor: 'rgba(255, 255, 255, 0.75)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 2,
        }],
    };
};

export const MessageChart: React.FC<{ sent_messages: MessageInfo[] }> = ({sent_messages}) => {
    Chart.register(TimeScale, CategoryScale, LinearScale, PointElement, LineElement);
    const [chartData, setChartData] = useState<any>(null);

    useEffect(() => {
        const processedData = processData(sent_messages);
        setChartData(processedData);
    }, [sent_messages]);

    if (!chartData) return <div>Loading...</div>;

    return (
        <div>
            <Line data={chartData} options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {color: 'rgba(255, 255, 255, 0.1)'},
                        ticks: {color: 'rgba(255, 255, 255, 0.7)'}

                    },
                    y: {
                        type: 'linear',
                        grid: {color: 'rgba(255, 255, 255, 0.1)'},
                        ticks: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            callback: function (value) {
                                if (Number.isInteger(value)) {
                                    return value;
                                }
                            }
                        }
                    }
                }
            }}/>
        </div>
    );
};
