import {Route, Routes} from "react-router-dom";
import App from "./App";
import React from "react";
import {StandardPage} from "./StandardPage";
import {C5NTermsOfServiceBuyer} from "./c5n/C5NTermsOfServiceCreator";
import {C5NPostStripeRegister} from "./c5n/tabs/C5NPostStripeRegister";
import {FeedbackButton} from "./feedback/FeedbackButton";
import {LandingPage} from "./LandingPage";
import {VerifyMagicLink} from "./auth/VerifyMagicLink";

export const SubGacekRoutes = () => {
    // https://www.creators.subdog.xyz/c5n_post_stripe?email_address=piotr.w.lewandowski+pl_test_interfix_01@gmail.com
    return (
        <Routes>
            <Route path="/"
                   element={<>
                       <LandingPage/>
                   </>}/>
            <Route path="/account"
                   element={<StandardPage> <FeedbackButton showText={false}/><App/></StandardPage>}/>
            <Route path="/terms-of-service-c5n"
                   element={<StandardPage><C5NTermsOfServiceBuyer/></StandardPage>}/>
            <Route path="/c5n_post_stripe"
                   element={<StandardPage><C5NPostStripeRegister/></StandardPage>}/>
            <Route path="/verify_magic_link" element={<StandardPage><VerifyMagicLink/></StandardPage>}/>
        </Routes>
    )
}
