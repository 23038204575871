import React from "react";
import './StandardPage.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';

type StandardPageProps = {
    children: React.ReactNode
}

export function StandardPage({children}: StandardPageProps) {
    return (
        <>{children}</>

    );

}