import React, {useState} from 'react';
import './FeedbackButton.css';
import {Button, Modal} from "react-bootstrap"; // Import your CSS file for styling
import "bootstrap-icons/font/bootstrap-icons.css";
import {useTranslation} from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import {FloatButton} from "antd";
import {MailOutlined} from "@ant-design/icons";

export const FeedbackButton = ({showText}: FeedbackButtonProps) => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [feedbackText, setFeedbackText] = useState('');
    const {t} = useTranslation()

    const handleClose = () => setShow(false);

    const handleSend = async () => {
        let apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT
        let response: Response = await fetch(apiEndpoint + "send_feedback", {
            method: "POST",
            body: JSON.stringify({
                email: email,
                feedback: feedbackText
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        if (!response.ok) {
            alert(t("feedback-message-not-sent"));
            // alert("Niestety coś poszło nie tak. Proszę wyślij maila na piotr.w.lewandowski+sd_feedback@gmail.com");
        } else {
            setEmail("");
            setFeedbackText("");
            alert(t("feedback-message-sent"));
            handleClose();
        }
    }

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    }

    const handleFeedbackTextChange = (event: any) => {
        setFeedbackText(event.target.value);
    }


    const handleShow = () => setShow(true);
    return (
        <>
            <FloatButton
                shape="square"
                type="primary"
                style={{scale: "1.25", padding: 2}}
                tooltip={"Skontaktuj się ze mną!"}
                description={"Mail"}
                icon={<MailOutlined />}
                onClick={handleShow} />

            <Modal show={show} onHide={handleClose} size={"lg"}>
                <Modal.Body>
                    <p>{t("have-feedback-modal-text")}</p>
                    <input required={true} type={"email"} className={"form-control"} id={"feedback-email"}
                           placeholder={t("your-email-placeholder")}
                           value={email}
                           onChange={handleEmailChange}></input>
                    <br/>
                    <textarea className={"form-control"} rows={5} id={"feedback-text-area"}
                              placeholder={t("your-message-placeholder")}
                              value={feedbackText}
                              onChange={handleFeedbackTextChange}
                    ></textarea>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("feedback-close-button-modal")}
                    </Button>
                    <Button variant="primary" onClick={handleSend}>
                        {t("feedback-send-button-modal")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export interface FeedbackButtonProps {
    showText: boolean

}