import React from "react";
import {Button, Card, Form, Input, message} from "antd";
import axios from "axios";
import {useTranslation} from "react-i18next";


export let AuthScreen = () => {
    const {t} = useTranslation();
    const signIn = React.useCallback(async ({email}: { email: string }) => {
        return requestMagicLink(email)
    }, [])
    const onSubmit = async (e: any) => {
        let values = {
            email: e['user-email']
        }
        try {
            await signIn(values)
            message.success(t("auth-screen-login-success"), 5)
        } catch (e: any) {
            message.error(t("auth-screen-login-failure"), 4)
        } finally {
        }
    }
    return (
        <Card style={{minWidth: "50vh"}} className={"mt-3"}>
            <Form
                name={"customer-login-link"}
                initialValues={{remember: true}}
                size={"large"}
                onFinish={onSubmit}
            >
                <p style={{
                    textAlign: "justify",
                    textJustify: "inter-word"
                }}>{t("auth-screen-login-paragraph")}</p>
                <Form.Item<string>
                    label={t("your-email-placeholder")}
                    name="user-email"
                    rules={[
                        {required: true, message: t("auth-screen-email-required")},
                        {type: 'email', message: t("auth-screen-email-format")}
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        {t("auth-screen-login-button-text")}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export const requestMagicLink = async (email: string) => {
    const res = await axios.post(
        "https://hcr2fnxpe8.execute-api.eu-west-1.amazonaws.com/prod/sign_in",
        {email}
    )
    return res.data
}