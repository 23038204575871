import {MessageOutlined, PieChartOutlined} from "@ant-design/icons";
import React from "react";
import {MenuItemType, SubMenuType} from "antd/lib/menu/hooks/useItems";
import {FinancialStats, FinancialStatsParams} from "../../financial/FinancialStats";
import {ProductStats, ProductStatsParams} from "../../product/ProductStats";
import {MDBCard} from "mdb-react-ui-kit";
import {AddMessagesCardBody} from "../../messages/AddMessages";
import {ListMessagesCardBody} from "../../messages/ListMessagesCardBody";
import {CreatorDataResponse, MessageInfo, MessageProductsData} from "../../App";
import {SpecialMessageTab} from "../../messages/SpecialMessageTab";

export const FinancialStatsKey = 'financial-stats'

export const FinancialStatsMenuItem: MenuItemType = {
    key: FinancialStatsKey,
    label: 'Statystyki finansowe',
}

export const FinancialStatsContent = ({balance_response, subscription_data}: FinancialStatsParams) => {
    return (
        <FinancialStats balance_response={balance_response}
                        subscription_data={subscription_data}/>
    )
}

export const YourProductStatsKey = 'your-product-stats'

export const YourProductStatsMenuItem: MenuItemType = {
    key: YourProductStatsKey,
    label: 'Statystyki produktów',
}


export const YourProductStatsContent = ({sent_messages}: ProductStatsParams) => {
    return (
        <ProductStats sent_messages={sent_messages}/>
    )
}

export const StatisticsAndDataKey = 'statistics-and-data'
export const StatisticsAndDataMenuItem: SubMenuType = {
    key: StatisticsAndDataKey,
    icon: React.createElement(PieChartOutlined),
    label: 'Statystyki i dane',
    children: [FinancialStatsMenuItem, YourProductStatsMenuItem]
}

export const AddMessageKey = 'add-message'

export const AddMessageMenuItem: MenuItemType = {
    key: AddMessageKey,
    label: 'Dodaj wiadomość',
}

export const forceReload = () => {
    window.location.reload();
}

export const AddMessageContents = () => {
    return (
        <MDBCard className={"mb-1"}>
            <AddMessagesCardBody reloadItems={forceReload}/>
        </MDBCard>
    )

}

export const ListMessagesKey = 'list-messages'

export const ListMessagesMenuItem: MenuItemType = {
    key: ListMessagesKey,
    label: 'Lista wiadomości',
}

export interface ListMessagesContentsProps {
    response: CreatorDataResponse
}

export const ListMessagesContents = ({response}: ListMessagesContentsProps) => {
    return (
        <MDBCard className={"mb-1"}>
            <ListMessagesCardBody
                items={response.message_products_data as MessageProductsData}
                reloadItems={forceReload}/>
        </MDBCard>
    )


}

export const SendOnDemandMessageKey = 'send-on-demand-message'

export const SendOnDemandMessageMenuItem: MenuItemType = {
    key: SendOnDemandMessageKey,
    label: 'Wyślij wiadomość na żądanie',
}

export interface SendOnDemandMessageContentsProps {
    sent_messages: MessageInfo[],

}

export const SendOnDemandMessageContents = ({sent_messages}: SendOnDemandMessageContentsProps) => {
    return (
        <SpecialMessageTab sent_messages={[]} reloadItems={forceReload}/>
    )
}

export const MessagesKey = 'messages'

export const MessagesMenuItem: SubMenuType = {
    key: MessagesKey,
    icon: React.createElement(MessageOutlined),
    label: 'Wiadomości',
    children: [AddMessageMenuItem, ListMessagesMenuItem, SendOnDemandMessageMenuItem]
}