import React from "react";
import {Col} from "antd";

export interface SimpleCardWrapperProps {
    children: React.ReactNode
}

export const SimpleCardWrapper = ({children}: SimpleCardWrapperProps) => {
    return (
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            {children}
        </Col>
    )
}