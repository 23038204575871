import React, {lazy, Suspense, useState} from "react";
import IntroContent from "../../content/IntroContent.json";
import AboutContent from "../../content/AboutContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
import SentContent from "../../content/SentContent.json";
import "antd/dist/reset.css";
import {Styles} from "../../styles/styles";
import Header from "../../components/Header";
import {useTranslation} from "react-i18next";
import {Divider} from "antd";

const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const ContentSubmitBlock = lazy(() => import("../../components/ContentSubmitBlock"));
const ContentAboutBlock = lazy(() => import("../../components/ContentAboutBlock"));

const Home = () => {
    let [isSent, setIsSent] = useState(false)
    const {t} = useTranslation();
    if (isSent) {
        return (<><Container>
            <Styles/>
            <ContentAboutBlock
                direction="left"
                title={SentContent.title}
                content={SentContent.text}
                section={SentContent.section}
                icon="graphs2.svg"
                id="about"
                hideForm={true}
                setIsSent={setIsSent}
            />
        </Container></>)
    }
    return (
        <Suspense fallback={null}>
            <Styles/>
            <Header/>
            <Container>
                <ScrollToTop/>
                <ContentSubmitBlock
                    direction="right"
                    title={t("intro-title-top")}
                    content={t("intro-content-top")}
                    button={IntroContent.button}
                    icon="developer.svg"
                    id="intro"
                    setIsSent={setIsSent}
                />
                <Divider/>
                <ContentBlock
                    direction="left"
                    title={ProductContent.title}
                    content={ProductContent.text}
                    icon="waving.svg"
                    id="product"
                />
                <Divider/>
                <ContentAboutBlock
                    direction="left"
                    title={AboutContent.title}
                    content={AboutContent.text}
                    section={AboutContent.section}
                    icon="graphs2.svg"
                    id="questions"
                    setIsSent={setIsSent}
                />
                <Divider/>
                <ContentBlock
                    direction="right"
                    title={MissionContent.title}
                    content={MissionContent.text}
                    icon="developer.svg"
                    id="mission"
                />

            </Container>
        </Suspense>
    );
};

export default Home;
