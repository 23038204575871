import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter} from "react-router-dom";
import {SubGacekRoutes} from "./components/SubGacekRoutes";
import i18n from "i18next";
import XHR from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {Translations} from "./translations";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


i18n
    .use(XHR)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init(Translations());


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <SubGacekRoutes/>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
