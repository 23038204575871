import {CreatorDataResponse} from "../../App";
import {Content} from "antd/lib/layout/layout";
import {FeedbackButton} from "../../feedback/FeedbackButton";
import {Button, Col, Form, Input, message, Row} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {API} from "aws-amplify";

export interface FillInDetailsProps {
    response: CreatorDataResponse
}

export const FillInDetails = ({response}: FillInDetailsProps) => {
    const {t} = useTranslation();
    const C5NFormMap: Map<string, JSX.Element> = new Map([
        ['pretty_name',
            <>
                <Form.Item<string>
                    label={t("c5n-fill-in-details-pretty-name-label")}
                    name="pretty-name"
                    required={true}
                    className={"mb-1"}
                >
                    <Input/>
                </Form.Item>
                <p className={"pt-0"}>{t("c5n-fill-in-details-pretty-name-explanation")}</p>
            </>
        ],
        ['slug',
            <>
                <Form.Item<string>
                    label={t("c5n-fill-in-details-slug-label")}
                    name="slug"
                    required={true}
                    className={"mb-1"}
                >
                    <Input/>
                </Form.Item>
                <p className={"pt-0"}>{t("c5n-fill-in-details-slug-explanation")}</p>
            </>
        ]
    ]);

    const onSubmit = (e: any) => {
        const pretty_name = e['pretty-name']
        const slug = e['slug']
        let should_return = false
        if (pretty_name === undefined || pretty_name === "") {
            should_return = true
            message.error("Wprowadź swoje imię autora!", 5)
        }
        if (slug === undefined || slug === "") {
            should_return = true
            message.error("Wprowadź swój identifykator", 5)
        }
        if (should_return) {
            return
        }
        const request = {
            type: "UpsertC5nAuthorDetails",
            slug: slug,
            pretty_name: pretty_name,
        }
        const amplifyInit = {
            body: request
        }
        let response = API.post("BackendAuth", "write_creator", amplifyInit);
        response.catch((error) => {
            let msg: string | undefined = error.response.data
            if (msg !== undefined) {
                message.error(msg, 5);
            } else {
                throw error
            }
        }).then(() => {
            window.location.reload();
        });


    }
    return (
        <Content className={"pt-1 px-5"}>
            <FeedbackButton showText={true}/>
            <Row justify={"center"}>
                <Col>
                    <h1 className={"text-center display-6"}>Witaj na pokładzie!</h1>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p style={{
                        textAlign: "justify",
                        textJustify: "inter-word"
                    }}>Cieszę się, że tutaj jesteś (pewnie bardziej niż Ty)! Żeby móc korzystać, musisz podać jeszcze
                        (tak, wiem... ) kilka informacji,
                        dzięki którym będziesz mógł w pełni korzstać z funkcjonalności subdoga</p>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form
                        name={"fill-in-details"}
                        size={"large"}
                        layout={"vertical"}
                        onFinish={onSubmit}
                    >
                        {response.missing_fields.map((field) => {
                            return C5NFormMap.get(field)
                        })}

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Wyślij!
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

        </Content>
    )
}