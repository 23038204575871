import {MenuItemType, SubMenuType} from "antd/lib/menu/hooks/useItems";
import React from "react";
import {ReadOutlined} from "@ant-design/icons";
import {SimpleCardWrapper} from "../../commons/SimpleCardWrapper";
import {
    Alert,
    Button,
    Card, Col,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Progress,
    Spin,
    Switch,
    Upload,
    Watermark
} from "antd";
import {useTranslation} from "react-i18next";
import {API} from "aws-amplify";
import {C5NProduct, CreatorDataResponse} from "../../App";
import Paragraph from "antd/lib/typography/Paragraph";
import axios from 'axios';

export const AddVirtualProductKey = 'add-virtual-product'

export function AddVirtualProductMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: AddVirtualProductKey,
        label: t("admin-panel-add-virtual-product"),
    }

}

export interface AddVirtualProductContentsProps {
    isPolish: boolean
    setResponse: (response: CreatorDataResponse) => void,
}

export const AddVirtualProductContents = ({isPolish, setResponse}: AddVirtualProductContentsProps) => {
    const {t} = useTranslation()
    let [price, setPrice] = React.useState<number | null>(null);
    const currency = isPolish ? "PLN" : "USD";
    let platformFee = Math.max(isPolish ?  5.55 : 2.00, Math.round(100 * (price ? price : 0) * 0.0555) / 100);

    const [fileList, setFileList] = React.useState<any[]>([]);
    const [imageFileList, setImageFileList] = React.useState<any[]>([]);
    const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const handleFileChange = (info: any) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setFileList(fileList);
    };

    const handleImageFileChange = (info: any) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setImageFileList(fileList);
    };
    let [progressElement, setProgressElement] = React.useState<React.ReactNode>(<></>)
    const doAddProduct = async (values: any) => {
        let shouldContinue = true;
        if (price === null) {
            alert(t("c5n-add-virtual-products-add-object-price-error"));
            return;
        }
        setFormDisabled(true)
        setProgressElement(<Alert type={"info"}
                                  message={<><Spin/>&nbsp;{t("add-product-adding-preparing-safe-link")}</>}/>)
        let fileToUpload = values.fileToUpload.file;
        const request = {
            type: "GeneratePresignedPutUrl",
            object_key: fileToUpload.name,
            content_type: fileToUpload.type
        }
        const amplifyInit = {
            body: request
        }
        let response = await API.post("BackendAuth", "write_creator", amplifyInit)
            .catch((e) => {
                setProgressElement(<Alert type={"error"} message={t("add-product-adding-preparing-safe-link-error")}/>)
                setFormDisabled(false)
                shouldContinue = false;
                return
            });
        if (!shouldContinue) {
            return;
        }
        let safeUrlToUpload = response.url as string;
        setProgressElement(<Alert type={"info"}
                                  message={<>{t("add-product-adding-uploading-file")}&nbsp;<Progress percent={0}/></>}/>)
        await axios.put(safeUrlToUpload, fileToUpload, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                // Update the progress element with the percentage
                setProgressElement(
                    <Alert type={"info"}
                           message={<>{t("add-product-adding-uploading-file")}&nbsp;<Progress percent={percentCompleted}/></>}/>
                );
            },
            headers: {
                'Content-Type': fileToUpload.type
            }
        }).catch((e) => {
            setProgressElement(<Alert type={"error"} message={t("add-product-adding-uploading-file-error")}/>)
            setFormDisabled(false)
            shouldContinue = false;
            return
        });
        if (!shouldContinue) {
            return;
        }

        let imageFileLocation = null;
        if (values.imageToUpload !== undefined) {
            setProgressElement(<Alert type={"info"}
                                      message={<><Spin/>&nbsp;{t("add-product-adding-preparing-safe-link-image")}</>}/>)
            let imageToUpload = values.imageToUpload.file;
            const request = {
                type: "GeneratePresingPutImageUrl",
                object_key: imageToUpload.name,
                content_type: imageToUpload.type
            }
            const amplifyInit = {
                body: request
            }
            let response = await API.post("BackendAuth", "write_creator", amplifyInit).catch((e) => {
                setProgressElement(<Alert type={"error"} message={t("add-product-adding-preparing-safe-link-image-error")}/>)
                setFormDisabled(false)
                shouldContinue = false
                return;
            });
            if (!shouldContinue) {
                return;
            }
            let safeUrlToUpload = response.url as string;
            setProgressElement(<Alert type={"info"}
                                      message={<><Spin/>&nbsp;{t("add-product-adding-image-progress")}</>}/>)
            await axios.put(safeUrlToUpload, imageToUpload, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // Update the progress element with the percentage
                    setProgressElement(
                        <Alert type={"info"}
                               message={<>{t("add-product-adding-image-progress")}&nbsp;<Progress percent={percentCompleted}/></>}/>
                    );
                },
                headers: {
                    'Content-Type': imageToUpload.type
                }
            }).catch((e) => {
                setProgressElement(<Alert type={"error"} message={t("add-product-adding-image-progress-error")}/>)
                setFormDisabled(false)
                shouldContinue = false;
                return
            });
            if (!shouldContinue) {
                return;
            }
            imageFileLocation = response.location as string
        }
        setProgressElement(<Alert type={"info"}
                                  message={<><Spin/>&nbsp;{t("add-product-creating-product")}</>}/>)
        const createProductRequest = {
            filename: fileToUpload.name,
            type: "CreateNewProduct",
            product_name: values.productName,
            product_description: values.productDescription,
            product_price: price * 100,
            product_currency: currency,
            image_file_name: imageFileLocation
        }
        const amplifyInitCreateProduct = {
            body: createProductRequest
        }
        await API.post("BackendAuth", "write_creator", amplifyInitCreateProduct).catch((e) => {
            setProgressElement(<Alert type={"error"} message={t("add-product-creating-product-error")}/>)
            setFormDisabled(false)
            shouldContinue = false;
            return;
        });
        if (!shouldContinue) {
            return;
        }
        setProgressElement(<Alert type={"success"} message={t("add-product-success")}/>)
        form.resetFields()
        setFileList([])
        setImageFileList([])
        let cdResponse: CreatorDataResponse = await API.get("BackendAuth", "read_creator", {});
        setResponse(cdResponse)
        setFormDisabled(false)
    }
    return (
        <Col  xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card hoverable title={t("admin-panel-add-virtual-product")}>
                <div style={{width: "100%"}} className={"mb-2"}>
                    {progressElement}
                </div>
                <Form layout={"vertical"}
                      form={form}
                      onFinish={doAddProduct}
                      disabled={formDisabled}
                >
                    <Form.Item
                        label={t("c5n-add-virtual-products-add-object-name-label")}
                        name={"productName"}
                        rules={[{required: true, message: t("add-product-product-name-required")}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={t("c5n-add-virtual-products-add-object-description-label")}
                        name={"productDescription"}
                        rules={[
                            {required: true, message: t("add-product-product-description-required")}
                        ]}>
                        <Input.TextArea
                            rows={3}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("c5n-add-virtual-products-add-object-price-label")}
                        name={"productPrice"}
                        rules={[
                            {required: true, message: t("add-product-product-price-required")},
                        ]}>
                        <InputNumber type={"number"}
                                     value={price}
                                     min={isPolish ? 5.56 : 2.01}
                                     precision={2}
                                     onChange={setPrice}
                                     step={0.01}
                                     required
                                     style={{width: "100%"}}
                                     addonAfter={currency}
                        />
                    </Form.Item>
                    {price && <Alert type={"info"} className={"mb-2"} message={<>
                        <b>{t("c5n-add-virtual-products-add-object-user-earnings")}: {(price - platformFee).toFixed(2)}</b>, {t("c5n-add-virtual-products-add-object-c5n-fee")}: {platformFee}</>}/>}
                    <Form.Item
                        label={t("c5n-add-virtual-products-add-object-file-label")}
                        name={"fileToUpload"}
                        rules={[{required: true, message: t("add-product-product-file-required")}]}
                    >
                        <Upload
                            fileList={fileList}
                            onChange={handleFileChange}
                            beforeUpload={() => false}
                        >
                            <Button block>{t("add-product-select-product-button-text")}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label={t("c5n-add-virtual-products-add-object-image-label")}
                        name={"imageToUpload"}
                    >
                        <Upload
                            fileList={imageFileList}
                            onChange={handleImageFileChange}
                            beforeUpload={() => false}
                        >
                            <Button block>{t("add-product-select-image-button-text")}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type={"primary"} htmlType={"submit"} block>
                            {t("c5n-add-virtual-products-add-object-button")}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Col>
    )
}


export const YourVirtualProductsKey = 'your-virtual-products'

export function YourVirtualProductsMenuItem(t: (key: string) => string): MenuItemType {
    return {
        key: YourVirtualProductsKey,
        label: t("admin-panel-your-virtual-products"),
    }
}

export interface YourVirtualProductsContentsProps {
    products: C5NProduct[]
    setResponse: (response: CreatorDataResponse) => void,
}

export const YourVirtualProductsContents = ({products, setResponse}: YourVirtualProductsContentsProps) => {
    let [storedProducts, setStoredProducts] = React.useState<C5NProduct[]>(products)
    const [editForm] = Form.useForm();
    const {t} = useTranslation()
    if (storedProducts.length === 0) {
        return (
            <SimpleCardWrapper>
                <Card>
                    {t("your-virtual-products-no-products-text")}
                </Card>
            </SimpleCardWrapper>
        )
    }
    return (
        <>            {storedProducts.map((product) => {
            return (
                <SimpleCardWrapper>
                    <Card hoverable
                          key={product.product_name}
                          cover={
                              <div style={{height: '200px', overflow: 'hidden'}}>
                                  {product.image_url_link ? (
                                      <img alt="example" src={product.image_url_link}
                                           style={{width: '100%', height: 'auto'}}/>
                                  ) : (
                                      <Watermark content={product.product_name}>
                                          <div style={{height: '200px', overflow: 'hidden'}}></div>
                                      </Watermark>
                                  )}
                              </div>
                          }
                          actions={[
                              <>{product.price / 100} {product.currency.toUpperCase()}</>,
                              <Paragraph
                                  className={"mx-1"}
                                  ellipsis={true}
                                  copyable>{product.stripe_link_url}</Paragraph>,
                              <Button
                                  onClick={() => {
                                      editForm.resetFields()
                                      Modal.confirm({
                                          icon: null,
                                          title: t("your-virtual-products-edit-product-title"),
                                          content: <EditVirtualProductForm form={editForm} product={product}/>,
                                          okText: t("your-virtual-products-edit-product-update-label"),
                                          cancelText: t("your-virtual-products-edit-product-cancel-update-label"),
                                          onOk: async () => {
                                              await editForm.validateFields()
                                                  .catch((r) => message.error("Will not update because of form in error", 5))
                                              const values = editForm.getFieldsValue()
                                              let doNotModifyCurrentImage = !values.deleteImage && !values.imageToUpload
                                              let shouldContinue = true;
                                              let imageLocation = undefined;
                                              if (!values.deleteImage && values.imageToUpload) {
                                                  let fileToUpload = values.imageToUpload.file;
                                                  const request = {
                                                      type: "GeneratePresingPutImageUrl",
                                                      object_key: fileToUpload.name,
                                                      content_type: fileToUpload.type
                                                  }
                                                  const amplifyInit = {
                                                      body: request
                                                  }
                                                  let response = await API.post("BackendAuth", "write_creator", amplifyInit).catch((e) => {
                                                      shouldContinue = false
                                                      return;
                                                  });
                                                  if (!shouldContinue) {
                                                      message.error("Will not update because of error in image upload", 5)
                                                      return;
                                                  }
                                                  await axios.put(response.url, fileToUpload, {
                                                      headers: {
                                                          'Content-Type': fileToUpload.type
                                                      }
                                                  }).catch((e) => {
                                                      shouldContinue = false;
                                                      return
                                                  });
                                                  if (!shouldContinue) {
                                                      message.error("Will not update because of error in image upload", 5)
                                                      return;
                                                  }
                                                  imageLocation = response.location as string
                                              }
                                              const request = {
                                                  type: "EditVirtualProduct",
                                                  product_name: product.product_name,
                                                  product_description: values.productDescription,
                                                  image_file_name: values.deleteImage ? null : imageLocation,
                                                  do_not_modify_image: doNotModifyCurrentImage
                                              }
                                              const amplifyInit = {
                                                  body: request
                                              }
                                              await API.post("BackendAuth", "write_creator", amplifyInit).catch((e) => {
                                                  shouldContinue = false;
                                                  return;
                                              });
                                              if (!shouldContinue) {
                                                  message.error("Will not update because of error in roduct update", 5)
                                                  return;
                                              }
                                              let cdResponse: CreatorDataResponse = await API.get("BackendAuth", "read_creator", {});
                                              setResponse(cdResponse)
                                              setStoredProducts(cdResponse.c5n_products)
                                              editForm.resetFields()
                                          },
                                          onCancel: () => {
                                              editForm.resetFields()
                                          }
                                      })
                                  }}
                              >{t("your-virtual-products-edit-button-text")}</Button>,
                              <Popconfirm
                                  title={t("your-virtual-product-deletion-modal-title")}
                                  description={t("your-virtual-product-deletion-modal-body")}
                                  onConfirm={async () => {
                                      const request = {
                                          type: "DeleteProduct",
                                          product_name: product.product_name,
                                      }
                                      const deleteRequest = {
                                          body: request
                                      }
                                      await API.post("BackendAuth", "write_creator", deleteRequest);
                                      let response: CreatorDataResponse = await API.get("BackendAuth", "read_creator", {});
                                      setStoredProducts(response.c5n_products)
                                  }}
                                  okText={t("your-virtual-product-deletion-modal-button-delete")}
                                  cancelText={t("your-virtual-product-deletion-modal-button-close")}
                              >
                                  <Button danger>{t("your-virtual-products-delete-button-text")}</Button>
                              </Popconfirm>
                          ]}
                    >
                        <Card.Meta title={<span
                            className={"text-center"}>{product.product_name}<br/>{product.author_name}</span>}/>
                        <div className={"mt-1"}>{product.product_description}</div>
                    </Card>
                </SimpleCardWrapper>
            )
        })}
        </>
    )
}

interface EditVirtualProductFormProps {
    form: FormInstance
    product: C5NProduct
}

const EditVirtualProductForm = ({product, form}: EditVirtualProductFormProps) => {
    const {t} = useTranslation()
    const [showImageEdit, setShowImageEdit] = React.useState<boolean>(true)
    const [imageFileList, setImageFileList] = React.useState<any[]>([]);
    const handleImageFileChange = (info: any) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setImageFileList(fileList);
    };
    return (<Form
        form={form}
        layout="vertical"
    >
        <Form.Item
            label={t("c5n-add-virtual-products-add-object-description-label")}
            name={"productDescription"}
            rules={[
                {required: true, message: t("add-product-product-description-required")}
            ]}
            initialValue={product.product_description}
        >
            <Input.TextArea
                rows={3}
                defaultValue={product.product_description}
            />
        </Form.Item>
        <Form.Item
            label={t("edit-product-remove-thumbnail-text")}
            name={"deleteImage"}
        >
            <Switch onChange={(checked) => {
                setShowImageEdit(!checked)
            }}/>
        </Form.Item>
        {showImageEdit && (
            <>
                <Form.Item
                    className={showImageEdit.toString()}
                    label={t("c5n-add-virtual-products-add-object-image-label")}
                    name={"imageToUpload"}
                >

                    <Upload
                        fileList={imageFileList}
                        onChange={handleImageFileChange}
                        beforeUpload={() => false}
                    >
                        <Button block>{t("add-product-select-image-button-text")}</Button>
                    </Upload>
                </Form.Item>
                <span>{t("edit-product-no-thumbnail-explanation")}</span>

            </>
        )}
    </Form>)
}

export function VirtualMenuTopItem(t: (key: string) => string): SubMenuType {
    return {
        key: 'virtual-menu-top',
        label: t("admin-panel-virtual-products"),
        icon: React.createElement(ReadOutlined),
        children: [AddVirtualProductMenuItem(t), YourVirtualProductsMenuItem(t)]
    }
}