export const Translations = () => {
    const translations: SingleTranslation[] = [
        {
            key: "your-account-button-top",
            en: "Your Account",
            pl: "Twoje Konto"
        },
        {
            key: "intro-title-top",
            pl: "Sprzedaj swój wirtualny produkt w mniej niż 10 minut",
            en: "Sell your virtual product in less than 10 minutes"
        },
        {
            key: "intro-content-top",
            pl: "Ty tworzysz dla swojej widowni, ja obsługuję płatności, wystawiam faktury i bezpiecznie dostarczam pliki. Prosta prowizja przy sprzedaży 5.55% (przynajmniej 5.55 PLN/2 USD). Żadnych innych opłat",
            en: "You create for your audience, I handle payments, file upload and storage and safely deliver files. Oh, and I do issue invoices on your behalf for your customer. Simple fee on sale 5.55% (at least 2 USD/5.55 PLN). No other costs"
        },
        {
            key: "register-tos-prefix",
            pl: "Rejestrując się akceptujesz",
            en: "By registering you accept"
        },
        {
            key: "register-tos-link",
            pl: "regulamin",
            en: "terms and conditions"
        },
        {
            key: "your-email-address",
            pl: "Twój adres email",
            en: "Your email address"
        },
        {
            key: "submit-button-top",
            pl: "Załóż darmowe konto w kilka minut",
            en: "Create free account in few minutes"
        },
        {
            key: "select-selling-in-usd",
            pl: "Sprzedaję w USD",
            en: "Sell in USD"
        },
        {
            key: "select-selling",
            pl: "Sprzedaję w",
            en: "Sell in"
        },
        {
            key: "select-selling-in-pln",
            pl: "Sprzedaję w PLN",
            en: "Sell in PLN"
        },
        {
            key: "select-country",
            pl: "Jestem w",
            en: "I'm in",
        },
        {
            key: "product-content-title",
            pl: "Tak proste, że moja babcia mogłaby korzystać",
            en: "So simple that my grandma could use it"
        },
        {
            key: "product-content-text",
            pl: "Dodawanie produktów przez jeden prosty formularz. Kupno, dostarczanie oraz odzyskiwanie zakupionych tak proste jak to możliwe. Automatyczne fakturowanie. Brak stałych kosztów, zupełnie darmowe konto, pobieram jedynie prowizję przy sprzedaży. Kupujący nie potrzebują zakaładać żadnego konta. Stworzone z myślą o osobach \"nietechnicznych\" - zarówno od strony Twórcy jak i Kupującego.",
            en: "Adding products through one simple form. Buying, delivering and retrieving purchases as simple as possible - everything is done via email. No upfront or subscription consts, account is totally free, I get my fee only on your sales. Created with non-technical people in mind - both from the Creator and Buyer side."
        },
        {
            key: "faq-how-much-it-costs-title",
            pl: "Ile to kosztuje?",
            en: "How much does it cost?"
        },
        {
            key: "faq-how-much-it-costs-content",
            pl: "Założenie konta oraz dodawanie produktów jest zupełnie darmowe. Moja całkowita prowizja wynosi 5.55% (przynajmniej 5.55 PLN/2 USD, w zależności od waluty)",
            en: "Creating an account and adding products is completely free. My total fee is 5.55% (at least 2 USD/5.55 PLN, depending on currency)"
        },
        {
            key: "faq-vat-invoice-title",
            pl: "Czy mogę wystawiać faktury VAT?",
            en: "Can I issue VAT invoices?"
        },
        {
            key: "faq-vat-invoice-content",
            pl: "Tak! Obecnie wymaga to ręcznego kroku z mojej strony (pracuję nad automatyzacją), ale po jednorazowej konfiguracji po każdej transkacji otrzymasz fakturę VAT.",
            en: "Yes! Currently it requires a manual step from my side (I am working on automation), but after one-time configuration after each transaction you'll receive a VAT invoice."
        },
        {
            key: "faq-vat-registered-business-title",
            pl: "Czy muszę prowadzić działalność gospodarczą?",
            en: "Do I need to run a business to use the platform?"
        },
        {
            key: "faq-vat-registered-business-content",
            pl: "Nie, nie musisz prowadzić działalności gospodarczej. Możesz sprzedawać swoje treści jako osoba fizyczna.",
            en: "No, you do not need to run a business. You can sell your content as a private person."
        },
        {
            key: "faq-what-content-can-i-sell-title",
            pl: "Jakie treści mogę sprzedawać?",
            en: "What content can I sell?"
        },
        {
            key: "faq-what-content-can-i-sell-content",
            pl: "Możesz sprzedawać prawie każdą treść wirtualną (z wyjątkiem pornografii lub innych treści tego rodzaju, które mogłyby mnie wpędzić w kłopoty).",
            en: "You can sell almost any virtual content (except for porn or other stuff that could get me in trouble)."
        },
        {
            key: "faq-how-do-i-know-money-safe-title",
            pl: "Skąd wiem, że moje pieniądze są bezpieczne?",
            en: "How do I know my money is safe?"
        },
        {
            key: "faq-how-do-i-know-money-safe-content",
            pl: "Do przetwarzania płatności (zarówno Klientów i Twórców) używam Stripe - to światowy lider w płatnościach online. Twoje pieniądze są bezpieczne.",
            en: "I use Stripe to process payments (both for Creators and Buyers) - it is a global leader in online payments. Your money is safe."
        },
        {
            key: "faq-how-do-i-get-paid-title",
            pl: "W jaki sposób otrzymam pieniądze?",
            en: "How do I get paid?"
        },
        {
            key: "faq-how-do-i-get-paid-content",
            pl: "Podczas rejestracji podasz swój numer konta bankowego. Pieniądze są przelewane na Twoje konto 8 dnia każdego miesiąca.",
            en: "During registration you'll provide your bank account number. Money is transferred to your account on 8th day of each month."
        },
        {
            key: "content-about-submit-button-text",
            pl: "Skorzystaj z platformy zbudowanej specjalnie dla Twórców",
            en: "Register on platform built especially for Creators"
        },
        {
            key: "mission-content-title",
            pl: "Dlaczego zbudowałem tę platformę?",
            en: "Why did I build this platform?"
        },
        {
            key: "mission-content-text",
            pl: "Lubię internet. Lubię niezależność jaką daje Twórcom. Doceniam też, że pozwolił tak wielu ludziom znaleźć społeczności o podobnych zainteresowaniach. W ostatnich latach jednak jest znacznie mniej niezależności w internecie. Chcę, żeby Twórcy mogli w prosty sposób uniezależnić się od wysokich prowizji. Chcę, żeby internet znowu był dziwny, zaskakujący, \"alternatywny\", ale też raczej bezpieczny. Zdaję sobie sprawę, że w dzisiejszych czasach wielu Twórców nie ma już umiejętności około IT - i nic w tym złego! Dlatego chcę, żeby ta platforma była jak najbardziej przyjazna dla użytkownika. Chcę, żeby Twórcy mogli skupić się na tworzeniu, a nie na technicznych problemach. Chcę, żeby Twórcy mogli zarabiać na swojej pracy, a nie na swoich danych. Chcę, żeby Twórcy mogli być niezależni. Chcę, żeby Twórcy mogli być wolni.",
            en: "I like the internet. I like the independence it gives to Creators. I also appreciate that it allowed so many people to find communities with similar interests. In recent years, however, there is much less independence on the internet. I want Creators to be able to easily free themselves from high fees. I want the internet to be weird, surprising, \"alternative\", but also rather safe again. I realize that nowadays many Creators no longer have IT skills - and there's nothing wrong with that! That's why I want this platform to be as user-friendly as possible. I want Creators to focus on creating, not on technical problems. I want Creators to earn money from their work, not from their data. I want Creators to be independent. I want Creators to be free."
        },
        {
            key: "admin-panel-open-sells-page",
            pl: "Otwórz swoją stronę sprzedaży",
            en: "Open your selling page"
        },
        {
            key: "admin-panel-virtual-products",
            pl: "Produkty wirtualne",
            en: "Virtual Products"
        },
        {
            key: "admin-panel-add-virtual-product",
            pl: "Dodaj produkt wirtualny",
            en: "Add Virtual Product"
        },
        {
            key: "admin-panel-your-virtual-products",
            pl: "Twoje produkty wirtualne",
            en: "Your Virtual Products"
        },
        {
            key: "admin-panel-account-statistics",
            pl: "Statystyki konta",
            en: "Account Statistics"
        },
        {
            key: "admin-panel-account-management",
            pl: "Zarządzanie kontem",
            en: "Account Management"
        },
        {
            key: "admin-panel-log-out",
            pl: "Wyloguj się",
            en: "Log out"
        },
        {
            key: "add-product-select-product-button-text",
            pl: "Wybierz plik produktu",
            en: "Select product file"
        },
        {
            key: "add-product-select-image-button-text",
            pl: "Wybierz obrazek miniaturki",
            en: "Select thumbnail image"
        },
        {
            key: "add-product-product-name-required",
            pl: "Nazwa produktu jest wymagana",
            en: "Product name is required"
        },
        {
            key: "add-product-product-description-required",
            pl: "Opis produktu jest wymagany",
            en: "Product description is required"
        },
        {
            key: "edit-product-remove-thumbnail-text",
            pl: "Usuń miniaturkę",
            en: "Remove thumbnail"
        },
        {
            key: "edit-product-no-thumbnail-explanation",
            pl: "Jeżeli nie dodasz miniaturki, obecna nie zostanie zmieniona",
            en: "If you do not add thumbnail, current one will not be changed"
        },
        {
            key: "add-product-product-price-required",
            pl: "Cena produktu jest wymagana",
            en: "Product price is required"
        },
        {
            key: "add-product-product-file-required",
            pl: "Plik produktu jest wymagany",
            en: "Product file is required"
        },
        {
            key: "add-product-adding-preparing-safe-link",
            pl: "Przygotowywanie bezpiecznego łącza do wgrania pliku...",
            en: "Preparing safe link to upload file..."
        },
        {
            key: "add-product-adding-preparing-safe-link-error",
            pl: "Błąd podczas generowania bezpiecznego łącza!",
            en: "Error while generating safe link!"
        },
        {
            key: "add-product-adding-uploading-file",
            pl: "Wgrywanie pliku...",
            en: "Uploading file..."
        },
        {
            key: "add-product-adding-uploading-file-error",
            pl: "Błąd podczas wgrywania pliku!",
            en: "Error while uploading file!"
        },
        {
            key: "add-product-adding-preparing-safe-link-image",
            pl: "Przygotowywanie bezpiecznego łącza do wgrania miniaturki...",
            en: "Preparing safe link to upload thumbnail..."
        },
        {
            key: "add-product-adding-preparing-safe-link-image-error",
            pl: "Błąd podczas generowania bezpiecznego łącza!",
            en: "Error while generating safe link!"
        },
        {
            key: "add-product-adding-image-progress",
            pl: "Wgrywanie pliku obrazu...",
            en: "Uploading image file..."
        },
        {
            key: "add-product-adding-image-progress-error",
            pl: "Błąd podczas wgrywania miniaturki!",
            en: "Error while uploading thumbnail!"
        },
        {
            key: "add-product-creating-product",
            pl: "Tworzenie produktu...",
            en: "Creating product..."
        },
        {
            key: "add-product-creating-product-error",
            pl: "Błąd podczas tworzenia produktu!",
            en: "Error while creating product!"
        },
        {
            key: "add-product-success",
            pl: "Produkt dodany pomyślnie!",
            en: "Product added successfully!"
        },
        {
            key: "account-management-strip-login-card-title",
            pl: "Konto Stripe",
            en: "Stripe Account"
        },
        {
            key: "account-management-strip-login-card-description",
            pl: "Zarządzanie kontem stripe, szczegóły płatności, itp.",
            en: "Manage your Stripe account, payment details, etc."
        },
        {
            key: "account-management-strip-login-card-button-text",
            pl: "Zaloguj się do Stripe",
            en: "Stripe Login"
        },
        {
            key: "your-virtual-products-edit-button-text",
            pl: "Edytuj",
            en: "Edit"
        },
        {
            key: "your-virtual-products-delete-button-text",
            pl: "Usuń",
            en: "Delete"
        },
        {
            key: "your-virtual-products-no-products-text",
            pl: "Brak produktów wirtualnych. Dodaj swój pierwszy produkt!",
            en: "No virtual products. Add your first product!"
        },
        {
            key: "your-virtual-products-edit-product-title",
            pl: "Edytuj produkt",
            en: "Edit product"
        },
        {
            key: "your-virtual-products-edit-product-update-label",
            pl: "Aktualizuj",
            en: "Update"
        },
        {
            key: "your-virtual-products-edit-product-cancel-update-label",
            pl: "Anuluj",
            en: "Cancel"
        },
        {
            key: "your-virtual-product-deletion-modal-title",
            pl: "Usuwanie produktu",
            en: "Deleting product"
        },
        {
            key: "your-virtual-product-deletion-modal-body",
            pl: "Czy na pewno chcesz usunąć produkt?",
            en: "Are you sure you want to delete product?"
        },
        {
            key: "your-virtual-product-deletion-modal-button-close",
            pl: "Zamknij",
            en: "Close"
        },
        {
            key: "your-virtual-product-deletion-modal-button-delete",
            pl: "Usuń!",
            en: "Delete!"
        },
        {
            key: "post-registration-check-your-email-box",
            pl: "Sprawdź swoją skrzynkę e-mail",
            en: "Check your email box"
        },
        {
            key: "post-registration-email-sent",
            pl: "Za kilka chwil otrzymasz e-mail z linkiem do rejestracji na Stripe. Po rejestracji na Stripe - pozostaje tylko sprzedać swój pierwszy produkt!",
            en: "In few short minutes you will receive an email with further instructions. After registration on Stripe - you can sell your first product!"
        },
        {
            key: "szewczyk-testimional-card-title",
            pl: "\"Polecam wszystkim twórcom który zależy na innowacyjnym podejściu i niezależności.\"",
            en: "\"I recommend to all creators who care about innovative approach and independence.\""
        },
        {
            key: "szewczyk-testimional-card-content",
            pl: "Słowa nie potrafią wyobrazić mojej wdzięczności za stworzenie tej kompleksowej platformy cyfrowej, która stała się fundamentem mojej pracy. " +
                "Wizja Piotra, dogłębna wiedza techniczna i niezachwiane poświęcenie dla tego projektu są nie tylko godne podziwu, ale również bardzo inspirujące. " +
                "Kompetencje Piotra w zakresie technologii cyfrowych są imponujące i stanowiły klucz do sukcesu całego przedsięwzięcia. " +
                "Dzięki jego kreatywnej pracy i zaangażowaniu, platforma ta nie tylko spełnia, ale przewyższa oczekiwania, oferując narzędzia i funkcjonalności, które znacząco ułatwiają i wzbogacają moją pracę.\n" +
                "Z całego serca dziękuję za nieoceniony wkład i nieustanne wsparcie.",
            en: "Words cannot express my gratitude for creating this comprehensive digital platform that has become the foundation of my work. " +
                "Piotr's vision, deep technical knowledge, and unwavering dedication to this project are not only admirable but also very inspiring. " +
                "Piotr's digital technology skills are impressive and have been key to the success of the entire project. " +
                "Thanks to his creative work and commitment, this platform not only meets but exceeds expectations, offering tools and functionalities that significantly facilitate and enrich my work. " +
                "I wholeheartedly thank you for your invaluable contribution and unwavering support."
        },
        {
            key: "auth-screen-login-success",
            pl: "Jeżeli posiadasz konto, za kilka chwil dostaniesz email z linkiem do logowania.",
            en: "If you have an account, you'll get an email with login link soon."
        },
        {
            key: "auth-screen-login-failure",
            pl: "Oops! Coś poszło nie tak!",
            en: "Oops! Something went wrong!"
        },
        {
            key: "auth-screen-how-to-set-up-account",
            pl: "Jak założyć konto?",
            en: "How to set-up an account?"
        },
        {
            key: "auth-screen-how-to-set-up-account-login-prefix",
            pl: "Wróć na",
            en: "Come back to"
        },
        {
            key: "auth-screen-how-to-set-up-account-login-main-page",
            pl: "stronę główną",
            en: "main page"
        },
        {
            key: "auth-screen-how-to-set-up-account-login-suffix",
            pl: "i skorzystaj z dostępnego tam formularza",
            en: "and use form available there"
        },
        {
            key: "auth-screen-login-paragraph",
            pl: "Jeżeli posiadasz konto Twórcy, na Twój adres email wyślę specjalny link do logowania",
            en: "If you have a Creator account, I'll send you a magic login link on your mail"
        },
        {
            key: "auth-screen-email-required",
            pl: 'Proszę podaj swój adres email!',
            en: "Please provide your address email"
        },
        {
            key: "auth-screen-email-format",
            pl: 'To nie jest poprawny adres email!',
            en: "It is not a proper email address"
        },
        {
            key: "auth-screen-login-button-text",
            pl: "Wyślij link do logowania",
            en: "Send the login link"
        }


    ]

    const result = {


        resources: {
            en: {
                translation: {
                    "c5n-fill-in-details-title": "You are one step before your account is ready!",
                    "c5n-fill-in-details-pretty-name-explanation": "This is the name that will be displayed to your customers. Think about it as an author's name.",
                    "c5n-fill-in-details-slug-explanation": "This is a unique identifier for your account. It will be used in the URL of your account (also when selling products!). It can only contain letters, numbers, and dashes. Think about it as a social media handle.",
                    "c5n-fill-in-details-pretty-name-label": "your author name",
                    "c5n-fill-in-details-slug-label": "your account handle",
                    "c5n-fill-in-details-submit": "Submit",

                    "c5n-top-nav-panel-name": "Creator Panel",
                    "c5n-simple-navbar-dropdown-name": "Virtual Products",
                    "c5n-simple-navbar-dropdown-add-c5n-product": "Add Virtual Content",
                    "c5n-simple-navbar-dropdown-list-c5n-product": "Your Virtual Products",

                    "c5n-top-nav-panel-logout": "Log out",
                    "c5n-top-nav-statistics": "Statistics",
                    "c5n-top-nav-account-settings": "Account Settings",

                    "c5n-statistics-card-header": "Statistics",
                    "c5n-statistics-card-stripe-link": "Stripe Login",
                    "c5n-statistics-card-explanation": "For now, you can see your sales statistics on Stripe. I will integrate it directly with this platform in future.",

                    "c5n-account-settings-stripe-header": "Stripe Account",
                    "c5n-account-settings-stripe-link": "Stripe Login",
                    "c5n-account-settings-stripe-explanation": "You can manage your Stripe account by clicking the button above. Payment details, etc.",
                    "c5n-account-settings-password-header": "Change Password",

                    "c5n-add-virtual-products-add-object-title": "Add Your Virtual Content",
                    "c5n-add-virtual-products-add-object-name-label": "Name of the content",
                    "c5n-add-virtual-products-add-object-name-warning": "For now, you cannot update this value after your product is created. This will be displayed to your customers.",
                    "c5n-add-virtual-products-add-object-description-label": "Description of the content",
                    "c5n-add-virtual-products-add-object-description-warning": "For now, you cannot update this value after your product is created. This will be displayed to your customers.",
                    "c5n-add-virtual-products-add-object-price-label": "Price of the content (including all the taxes)",
                    "c5n-add-virtual-products-add-object-price-error": "Price must be at least 5.56",
                    "c5n-add-virtual-products-add-object-user-earnings": "Your earnings",
                    "c5n-add-virtual-products-add-object-c5n-fee": "My fee",
                    "c5n-add-virtual-products-add-object-currency-label": "Currency",
                    "c5n-add-virtual-products-add-object-file-label": "File to upload",
                    "c5n-add-virtual-products-add-object-image-label": "Select thumbnail image for your product (optional)",
                    "c5n-add-virtual-products-add-object-button": "Add!",
                    "c5n-add-virtual-products-add-object-success": "Your product has been added successfully!",
                    "c5n-add-virtual-products-add-object-success-go-to-products": "Go to your products",

                    "c5n-list-virtual-products-see-selling-page": "See your selling page (as seen by your customers)",
                    "c5n-list-virtual-products-copy-tooltip": "Copy link to your clipboard. You can just share this link with your customers, after they'll pay - everything else just works.",
                    "c5n-list-virtual-product-deletion-modal-title": "Are you sure you want to delete",
                    "c5n-list-virtual-product-deletion-modal-body": "This action cannot be undone. Feel free to contact me if you have any doubts - piotr.w.lewandowski+c5n@gmail.com",
                    "c5n-list-virtual-product-deletion-modal-button-close": "Close",
                    "c5n-list-virtual-product-deletion-modal-button-delete": "Permanently Delete",

                    "c5n-tax-registration-alert": "If you need to register for tax purposes (e.g. VAT), please contact me at piotr.w.lewandowski+c5n_tax@gmail.com. I am working on making this process self-served, but for now I need to do it manually.",
                    "c5n-creator-register-title": "Register as a Creator on subdog's c5n",
                    "c5n-creator-register-motivation": "You can start earning money on your virtual content - pdf, video, pictures, audio. Just register below.",
                    "c5n-creator-register-email-address": "Your email address",
                    "c5n-creator-register-button-label": "Register!",
                    "c5n-creator-register-accept-terms": "By registering you accept terms and conditions of subdog's c5n.",
                    "c5n-creator-register-tab-title-1": "How much you can earn?",
                    "c5n-creator-register-tab-title-2": "Video explanation",
                    "c5n-creator-register-tab-title-3": "How does it work",
                    "c5n-creator-register-tab-content-1": "I take only either 3 USD or 5.55% fee, whichever is higher. Yes, that's all. The rest is yours.",
                    "c5n-creator-register-tab-content-2": "subdog's c5n is a platform for selling virtual content. It's a place where you can sell your pdfs, videos, pictures, audio - almost any virtual content " +
                        "(except for adult content, especially pornography or other stuffs of that sort that would get me in trouble).",
                    "c5n-creator-register-tab-content-3-1": "Simplicity. I take care of the payment processing, invoicing (VAT invoices included), hosting and delivery of the content.",
                    "c5n-creator-register-tab-content-3-2": "You focus on building best products as well as your audience.",
                    "c5n-creator-register-tab-content-3-3": "At the end of each month I'll send you an email with all your invoices.",

                    "c5n-creator-register-accept-terms-link": "Click here to see terms and conditions",

                    "c5n-creator-register-sent": "Shortly, you should receive an email with further instructions. In case of any issues do not hesitate to contact me at piotr.w.lewandowski+c5n@gmail.com",

                    "c5n-creator-register-explanation-1": "You register below by providing your email adress. By doing this you accept subdog c5n terms.",
                    "c5n-creator-register-explanation-2": "I'll send you an email address with Stripe registration link. I use stripe to process all payments (do not worry, I pay all the fees!)",
                    "c5n-creator-register-explanation-3": "After you'll complete your registration (if you drop off along a way just input your email below!) you'll receive your password and username that you can login to your Creator Dashboard.",
                    "c5n-creator-register-explanation-4": "Then you can add your virtual products. You remain full owner and take full responsibility for content provided. I do not accept pornography and other stuff that could put me away from my family.",
                    "c5n-creator-register-explanation-5": "You can then either share your C5N profile page with all your products, or simply share payment links directly. After customer will pay, they will receive protected, pre-signed url to download your content.",
                    "c5n-creator-register-explanation-6": "After each payment is made you'll receive an invoice (on your email) for tax purposes. Yes I am working on improving that.",
                    "c5n-creator-register-explanation-7": "After each transaction money (minus my fee) goes directly to your Stripe account. You'll get your payout 8th-ish day of each month. It is done by Stripe, fully automated, so I cannot steal your funds.",

                    "c5n-post-register": "In few short minutes you will receive an email with further instructions. In case of any issues do not hesitate to contact me at piotr.w.lewandowski+c5n@gmail.com",

                    "feedback-message-not-sent": "Unfortunately something went wrong. Please send an email to piotr.w.lewandowski+c5n@gmail.com",
                    "feedback-message-sent": "Message sent!",
                    "feedback-close-button-modal": "Close",
                    "feedback-send-button-modal": "Send",
                    "your-email-placeholder": "Your email address",
                    "your-message-placeholder": "Your message",
                    "have-feedback-button-title": "Have feedback?",
                    "have-feedback-modal-text": "If I can improve something, let me know. I am building the service myself, so please be understanding!",

                    "frequently-asked-questions-top": "Frequently Asked Questions",
                    "why-am-i-doing-this-top": "Why am I doing this?",
                }
            },
            pl: {
                translation: {
                    "c5n-fill-in-details-title": "Jesteś tylko krok od tego, aby Twoje konto było gotowe!",
                    "c5n-fill-in-details-pretty-name-explanation": "To jest nazwa (imię), która będzie wyświetlana Twoim klientom. Będzie wyświetlana w mailach oraz na Twojej stronie sprzedażowej",
                    "c5n-fill-in-details-slug-explanation": "To jest unikalny identyfikator Twojego konta. Będzie używany w adresie URL Twojego konta (np. www.subdog.xyz/twoj-identifikator również podczas sprzedaży produktów!). Może zawierać tylko litery, cyfry i myślniki. To jest jak nazwa użytkownika w mediach społecznościowych.",
                    "c5n-fill-in-details-pretty-name-label": "Imię autora",
                    "c5n-fill-in-details-slug-label": "Twój identyfikator konta",
                    "c5n-fill-in-details-submit": "Wyślij",

                    "c5n-top-nav-panel-name": "Panel Twórcy",
                    "c5n-simple-navbar-dropdown-name": "Wirtualne Produkty",
                    "c5n-simple-navbar-dropdown-add-c5n-product": "Dodaj Wirtualny Produkt",
                    "c5n-simple-navbar-dropdown-list-c5n-product": "Twoje Wirtualne Produkty",

                    "c5n-top-nav-panel-logout": "Wyloguj",
                    "c5n-top-nav-statistics": "Statystyki",
                    "c5n-top-nav-account-settings": "Ustawienia Konta",

                    "c5n-statistics-card-header": "Statystyki",
                    "c5n-statistics-card-stripe-link": "Zaloguj się do Stripe",
                    "c5n-statistics-card-explanation": "Na razie możesz zobaczyć swoje statystyki sprzedaży na Stripe. W przyszłości zintegruję to bezpośrednio z tą platformą.",

                    "c5n-account-settings-stripe-header": "Konto Stripe",
                    "c5n-account-settings-stripe-link": "Zaloguj się do Stripe",
                    "c5n-account-settings-stripe-explanation": "Możesz zarządzać swoim kontem Stripe, klikając przycisk powyżej. Szczegóły płatności itp.",
                    "c5n-account-settings-password-header": "Zmień Hasło",

                    "c5n-add-virtual-products-add-object-title": "Dodaj Swój Wirtualny Produkt",
                    "c5n-add-virtual-products-add-object-name-label": "Nazwa produktu",
                    "c5n-add-virtual-products-add-object-name-warning": "Na razie nie możesz zaktualizować tej wartości po utworzeniu produktu. Będzie ona wyświetlana Twoim klientom.",
                    "c5n-add-virtual-products-add-object-description-label": "Opis produktu",
                    "c5n-add-virtual-products-add-object-description-warning": "Na razie nie możesz zaktualizować tej wartości po utworzeniu produktu. Będzie ona wyświetlana Twoim klientom.",
                    "c5n-add-virtual-products-add-object-price-label": "Cena produktu (wliczając wszystkie podatki)",
                    "c5n-add-virtual-products-add-object-price-error": "Cena musi wynosić co najmniej 5.56",
                    "c5n-add-virtual-products-add-object-user-earnings": "Twoje zarobki",
                    "c5n-add-virtual-products-add-object-c5n-fee": "Moja prowizja",
                    "c5n-add-virtual-products-add-object-currency-label": "Waluta",
                    "c5n-add-virtual-products-add-object-file-label": "Plik do wgrania",
                    "c5n-add-virtual-products-add-object-image-label": "Wybierz obrazek miniaturki dla Twojego produktu (opcjonalnie)",
                    "c5n-add-virtual-products-add-object-button": "Dodaj!",
                    "c5n-add-virtual-products-add-object-success": "Twój produkt został dodany pomyślnie!",
                    "c5n-add-virtual-products-add-object-success-go-to-products": "Idź do swoich produktów",

                    "c5n-list-virtual-products-see-selling-page": "Zobacz swoją stronę sprzedaży (tak jak widzą ją Twoi klienci)",
                    "c5n-list-virtual-products-copy-tooltip": "Skopiuj link do schowka. Możesz po prostu udostępnić ten link swoim klientom, po opłaceniu - wszystko inne po prostu działa.",
                    "c5n-list-virtual-product-deletion-modal-title": "Czy na pewno chcesz usunąć",
                    "c5n-list-virtual-product-deletion-modal-body": "Tej akcji nie można cofnąć. Nie wahaj się skontaktować ze mną, jeśli masz jakiekolwiek wątpliwości - piotr.w.lewandowski+c5n@gmail.com",
                    "c5n-list-virtual-product-deletion-modal-button-close": "Zamknij",
                    "c5n-list-virtual-product-deletion-modal-button-delete": "Trwale usuń",

                    "c5n-tax-registration-alert": "Jeśli musisz się zarejestrować w celach podatkowych (np. VAT), skontaktuj się ze mną pod adresem at piotr.w.lewandowski+c5n_tax@gmail.com. Pracuję nad tym, aby ten proces był samobsługowy, ale na razie muszę to zrobić ręcznie.",
                    "c5n-creator-register-title": "Zarejestruj się jako Twórca na subdogowym c5n",
                    "c5n-creator-register-motivation": "Chcesz sprzedać wirtualne treści w internecie? Tutaj możesz - najprościej jak to możliwe.",
                    "c5n-creator-register-email-address": "Twój adres email",
                    "c5n-creator-register-button-label": "Zarejestruj się!",
                    "c5n-creator-register-accept-terms": "Rejestrując się akceptujesz regulamin subdogowego c5n.",
                    "c5n-creator-register-tab-title-1": "Ile możesz zarobić?",
                    "c5n-creator-register-tab-title-2": "Wideo z wyjaśnieniem",
                    "c5n-creator-register-tab-title-3": "Jak to działa?",
                    "c5n-creator-register-tab-content-1": "Biorę tylko 5.55% prowizji (nie mniej niż 5.55 PLN). Tak, to wszystko. Tak, cała reszta idzie do Ciebie.",
                    "c5n-creator-register-tab-content-2": "subdogowe c5n to platforma do sprzedaży treści wirtualnych. To miejsce, w którym możesz sprzedawać swoje pdfy, filmy, zdjęcia, audio - prawie każdą treść wirtualną " +
                        "(z wyjątkiem treści dla dorosłych, zwłaszcza pornografii lub innych treści tego rodzaju, które mogłyby mnie wpędzić w kłopoty).",
                    "c5n-creator-register-tab-content-3-1": "Prostota. Zajmuję się przetwarzaniem płatności, fakturowaniem (włączając faktury VAT), hostowaniem i dostarczaniem treści.",
                    "c5n-creator-register-tab-content-3-2": "Skup się na tworzeniu najlepszych produktów oraz swojej publiczności.",
                    "c5n-creator-register-tab-content-3-3": "Na koniec każdego miesiąca wyślę Ci email z wszystkimi Twoimi fakturami.",

                    "c5n-creator-register-accept-terms-link": "Kliknij tutaj, aby zobaczyć regulamin",

                    "c5n-creator-register-sent": "Za kilka chwil na Twoim emailu powinna znaleźć się wiadomość z dalszymi instrukcjami. W razie jakichkolwiek pytań - piotr.w.lewandowski+c5n@gmail.com",

                    "c5n-creator-register-explanation-1": "Rejestrujesz się poniżej, podając swój adres e-mail. Rejestrując się akceptujesz regulamin subdogowego c5n.",
                    "c5n-creator-register-explanation-2": "Wyślę Ci email z linkiem do rejestracji w Stripe. Używam Stripe do przetwarzania wszystkich płatności (nie martw się, opłaty biorę na siebie!)",
                    "c5n-creator-register-explanation-3": "Po zakończeniu rejestracji (jeśli zrezygnujesz po drodze, po prostu wprowadź swój email poniżej!) otrzymasz swoje hasło i nazwę użytkownika, którą możesz użyć do zalogowania się do swojego Panelu Twórcy.",
                    "c5n-creator-register-explanation-4": "Następnie możesz dodać swoje wirtualne produkty. Pozostajesz pełnym właścicielem i ponosisz pełną odpowiedzialność za dostarczane treści. Nie akceptuję pornografii i innych treści, które mogłyby mnie odciągnąć od mojej rodziny na niechciane wyjazdy.",
                    "c5n-creator-register-explanation-5": "Możesz udostępnić swoją stronę profilową C5N ze wszystkimi swoimi produktami, lub po prostu udostępnić bezpośrednie linki do płatności. Po opłaceniu klient otrzyma chroniony, wstępnie podpisany adres URL do pobrania Twojej treści.",
                    "c5n-creator-register-explanation-6": "Po każdej opłacie otrzymasz fakturę (na swój email) do celów podatkowych. Tak, pracuję nad poprawą tego.",
                    "c5n-creator-register-explanation-7": "Po każdej transkacji Twoja część (minus moja prowizja) trafia na Twoje konto Stripe. Otrzymasz swoją wypłatę około 8-go dnia każdego miesiąca. Jest to wykonywane przez Stripe, w pełni zautomatyzowane, więc Twoje fundusze są bezpieczne. Masz pełen podgląd przez panel Stripe.",

                    "c5n-post-register": "Za kilka chwil na Twoim emailu powinna znaleźć się wiadomość z dalszymi instrukcjami. W razie jakichkolwiek pytań - piotr.w.lewandowski+c5n@gmail.com",

                    "feedback-message-not-sent": "Niestety coś poszło nie tak. Proszę wyślij maila na piotr.w.lewandowski+c5n@gmail.com",
                    "feedback-message-sent": "Wiadomość wysłana!",
                    "feedback-close-button-modal": "Zamknij",
                    "feedback-send-button-modal": "Wyślij!",
                    "your-email-placeholder": "Twój adres email",
                    "your-message-placeholder": "Twoja wiadomość",
                    "have-feedback-button-title": "Masz feedback?",
                    "have-feedback-modal-text": "Jeżeli mogę coś polepszyć, daj mi znać. Buduję serwis sam, więc proszę o wyrozumiałość!",

                    "frequently-asked-questions-top": "Często zadawane pytania",
                    "why-am-i-doing-this-top": "Dlaczego to robię?",
                }
            }
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    };
    translations.forEach((translation) => {
        // @ts-ignore
        result.resources.en.translation[translation.key] = translation.en
        // @ts-ignore
        result.resources.pl.translation[translation.key] = translation.pl
    })
    return result
}

interface SingleTranslation {
    key: string,
    en: string,
    pl: string
}